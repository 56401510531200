import React from 'react'
import { AppMessage } from 'messages'
import { go, routes } from 'routing'
import { ChevronButton } from '../ChevronButton'
import { downloadImage } from 'utils/dowloadImage'
import { getUserId } from 'store/user'
import { apiPostImage } from 'api/api-post'
import './End.css'

export const ParticipateEnd: React.FC = () => {

  const ref = React.useRef<HTMLDivElement>(null)

  const download = () => {
    const message = AppMessage.send('ScreenshotRequest', { blob: null as null | Blob })
    const { blob } = message.props
    if (blob) {
      downloadImage(blob, { filename: `lightcode-${getUserId()}` })
    }
    else {
      console.warn('Screenshot failed.')
    }
  }

  const share = () => {
    AppMessage.send('ShareModal:Show')
  }

  return (
    <div ref={ref} className='Participate End absolute-fill'>
      <div className='Options'>

        <ChevronButton
          noChevron
          onClick={download}
          label='Télécharger' />
        
        <ChevronButton
          noChevron
          onClick={share}
          label='Partager' />
        
        <div className='Spacing' />

        <ChevronButton
          onClick={go(routes.WAITING)}
          label='Suivant' />

      </div>      
    </div>
  )
}
