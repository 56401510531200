
import { Message } from 'some-utils/message'
import * as messages from 'messages'
import * as user from 'store/user'
import * as theme from 'theme'
import { routes } from 'routing'
import { apiPost } from 'api/api-post'
import { userIdUpdateTexture } from "view/gl/user-id-assets"

Object.assign(window, {
  messages,
  theme,
  routes,
  user,
  Message,
  apiPost,
  userIdUpdateTexture,
})
