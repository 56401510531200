import React from 'react'
import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import { FrameInvalidator, makeSceneComponent } from 'three-utils/react-three-fiber'
import { Route } from 'some-utils/router'
import { Animation } from 'some-utils/Animation'
import { routes } from 'routing'
import { Post } from './post/post'
import { sharedUniforms, SharedUniformsHandler } from './SharedUniforms'
import { LineBlob } from './blob/LineBlob'
import { Tunnel } from './tunnel/Tunnel'
import { Tiles } from './tiles/Tiles'
import { Stripes } from './stripes/Stripes'
import { Lightcode } from './lightcode/Lightcode'
import { createLineBackground } from './blob/line-background'
import { UserId } from './UserId'
import { AutoPauseDuration } from 'three-utils/react-three-fiber/AutoPauseDuration'

const EndBackground = makeSceneComponent((_, { addToScene }) => {
  const background = createLineBackground({
    uShaderTime: sharedUniforms.uTime,
    uCenter: { value: new THREE.Vector2() },
  })
  addToScene(background.mesh)
  Animation.duringWithTarget(background, 2, ({ progress }) => {
    background.uOpacity.value = progress
  })
})

export const GlScene: React.FC = () => {

  return (
    <Canvas
      frameloop='demand'
      style={{ position: 'absolute' }}
      dpr={window.devicePixelRatio}
    >
      <kit.react.OrbitControls element={document.body} toggleWithSpace restorePosition />
      <primitive attach='background' object={new THREE.Color('#111')} />

      <FrameInvalidator />
      <SharedUniformsHandler />
      <ambientLight />

      <Route path={routes.HOME} exact>
        <Stripes />
      </Route>

      <Route path={routes.EXPERIENCE} exact>
        {/* <SpiralScene /> */}
      </Route>

      <Route path={routes.PARTICIPATE} exact>
        <LineBlob />
      </Route>

      <Route path={routes.PARTICIPATE_2} exact>
        <Tunnel />
      </Route>

      <Route path={routes.PARTICIPATE_3} exact>
        <Tiles />
      </Route>

      <Route path={routes.PARTICIPATE_COMPUTE} exact>
        <Stripes />
      </Route>

      <Route path={[routes.PARTICIPATE_END, routes.SHARE]} exact>
        <AutoPauseDuration value={30}/>
        <EndBackground />
      </Route>

      <Route path={[routes.PARTICIPATE_COMPUTE, routes.PARTICIPATE_END]} exact>
        <Lightcode />
      </Route>

      <Route path={routes.WAITING} exact>
        <Lightcode />
        {/* <SpiralScene /> */}
      </Route>

      <Post />
    </Canvas>
  )
}
