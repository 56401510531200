import { api } from 'types'
import { AppMessage } from 'messages'
import { routes } from 'routing'
import { Observable } from 'some-utils/observables'
import { location } from 'some-utils/router'
import { apiPost } from 'api/api-post'
import { useObservable } from 'some-utils/react'

export enum UserInput {
  LineBlob = 'LineBlob',
  Tunnel = 'Tunnel',
  Tiles = 'Tiles',
}

export type UserInputValue = {
  name: string
  value: number
}



export const userInputs = new Observable<{ [key: string]: UserInputValue }>({
  [UserInput.LineBlob]: { name: UserInput.LineBlob, value: 0 },
  [UserInput.Tunnel]: { name: UserInput.Tunnel, value: 0 },
  [UserInput.Tiles]: { name: UserInput.Tiles, value: 0 },
})

export const user = new Observable<api.User>({
  id: -1,
  hash: 'none',
  answers: [0, 0, 0],
  created: new Date(),
})

export const createUser = async () => {
  const answers = Object.values(userInputs.value).map(u => u.value)
  const response = await apiPost({ type: 'create-user', args: { answers }})
  user.value = response.data.user as api.User
  console.log(`user.id: ${user.value.id} user.hash: ${user.value.hash}`)
  return user.value
}

export const pushCurrentUserToCue = async () => {
  const { id } = user.value

  if (id !== -1) {
    console.log('user-wait-lightcode', user.value)
    const response = await apiPost({ type: 'user-wait-lightcode', args: { id }})
    const { ok, data } = response
    console.log({ ok, ...data })
  }

  else {
    console.warn(`Current user cannot be pushed to cue (invalid id).`)
  }
}

export type UserWaitingInfo = {
  isPlaying: boolean
  isWaiting: boolean
  isGone: boolean
  position: number
  playing: {
    timeStamp: number
    remainingTime: number
  }
  waiting: {
    timeStamp: number
    remainingTime: number
  }
}

export const getUserWaitingInfo = async (user: api.User): Promise<UserWaitingInfo> => {
  const { data } = await apiPost({ type: 'get-waiting-user-info' }) as api.WaitingInfoResponse
  const { playing, waiting, waitingQueue } = data
  const isPlaying = playing.id === user.id
  const isWaiting = waiting.includes(user.id)
  const { eta, position } = waitingQueue.find(x => x.id === user.id) ?? { eta: -1, position: -1 }
  return { 
    isPlaying, 
    isWaiting,
    isGone: isPlaying === false && isWaiting === false,
    playing: {
      timeStamp: data.playingTimeStamp,
      remainingTime: data.playing.remainingTime,
    },
    waiting: {
      timeStamp: data.waitingTimeStamp,
      remainingTime: eta,
    },
    position,
  } 
}

export const getCurrentUserWaitingInfo = () => getUserWaitingInfo(user.value)

export const debug_createUserAndPushToCue = async ({ count = 3 } = {}) => {
  for (let i = 0; i < count; i++) {
    await createUser()
    await pushCurrentUserToCue()
  }
}

export const debug_createUserAndUseIt = async () => {
  user.value = await createUser()
}

/**
 * Initialize the user logic.
 * 
 * (Watch path changes, listen to user inputs, etc.)
 */
export function* initUser() {

  yield location.pathname.logOnChange('pathname')

  yield location.pathname.onValue(routes.PARTICIPATE_COMPUTE, () => {
    if (user.value.id === -1) {
      createUser()
    }
  })

  yield location.pathname.onValue(routes.WAITING, async () => {
    if (user.value.id !== -1) {
      pushCurrentUserToCue()
    }
  })
  
  yield AppMessage.on<UserInputValue>('UserInput', input => {
    const { name } = input
    userInputs.setValue({ ...userInputs.value, [name]: input })
  })
}



export const getUserId = () => {
  const { id } = user.value
  return `#${(id === -1 ? 999 : id).toString().slice(0, 3).padStart(3, '0')}`
}

export const useUserId = () => {
  useObservable(user)
  return getUserId()
}

