export const easeGlsl = /* glsl */`

  float easeInout2(float x) {
    return x < 0.5
      ? 2.0 * x * x
      : 1.0 - 2.0 * (x = 1.0 - x) * x;
  }

  float easeInout3(float x) {
    return x < 0.5
      ? 4.0 * x * x * x
      : 1.0 - 4.0 * (x = 1.0 - x) * x * x;
  }
`