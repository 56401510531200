import glsl from 'babel-plugin-glsl/macro'





export const vertexShader = glsl /* glsl */ `

#pragma glslify: noise = require(glsl-noise/classic/3d.glsl)

attribute vec3 center;

uniform float uShaderTime;
varying vec2 vUv;
varying vec4 vWorldPosition;
varying vec3 vCenter;

void main()	{
  vUv = uv;
  vWorldPosition = modelMatrix * vec4(position, 1.0);
  vCenter = center;

  vec3 p1 = position * 0.1 + vec3(5.0, 3.0 + -uShaderTime * 1.0, 1.0 + uShaderTime * 0.2);
  float n1 = noise(p1);
  vec3 p2 = position + vec3(n1, 0.0, n1) * 1.3;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(p2, 1.0);
}

`





export const fragmentShader = glsl /* glsl */ `

#pragma glslify: noise = require(glsl-noise/classic/3d.glsl)
#pragma glslify: inverseLerp = require(../../glslified/inverseLerp.glsl)
#pragma glslify: hermite2nd = require(../../glslified/hermite2nd.glsl)

uniform float uShaderTime;
uniform sampler2D uGradientMap;
uniform sampler2D uShapeMap;
varying vec2 vUv;
varying vec4 vWorldPosition;
varying vec3 vCenter;

void main() {
  vec3 p = vec3(vUv * vec2(5.0, 1.0), 0.0);
  float n = -2.0 + noise(vWorldPosition.xyz) * 1.5 + vUv.y * 5.0;
  float a = smoothstep(-0.01, 0.01, n);

  vec2 coord = vec2(1.0 - (vUv.y * -0.4 + uShaderTime * 0.2 * 0.7), vUv.x);
  vec4 texel1 = texture2D(uGradientMap, coord);
  vec4 texel2 = texture2D(uShapeMap, coord);

  float thickness = mix(1.5, 10.0, texel1.r * texel1.r * texel1.r * vCenter.r) * hermite2nd(inverseLerp(-3.0, -8.0, vWorldPosition.z));
  vec3 afwidth = fwidth(vCenter.xyz);
  vec3 edge3 = smoothstep((thickness - 1.0) * afwidth, thickness * afwidth, vCenter.xyz);
  float edge = 1.0 - min(min(edge3.x, edge3.y), edge3.z);

  gl_FragColor.rgb = texel1.rgb;

  // White boost
  gl_FragColor.rgb = 1.0 - gl_FragColor.rgb;
  gl_FragColor.rgb *= mix(gl_FragColor.rgb, vec3(1.0), 0.15);
  gl_FragColor.rgb = 1.0 - gl_FragColor.rgb;

  gl_FragColor.a = min(edge, inverseLerp(-3.0, -8.0, vWorldPosition.z));
  // gl_FragColor.rgb = mix(texel1.rgb * (vCenter.x + vCenter.y), vec3(1.0), texel2.a * 0.9);
  // gl_FragColor.a = min(max(edge, texel2.a), inverseLerp(-3.0, -8.0, vWorldPosition.z));
}

`
