import { getPathname, getPathnameOld, setPathname } from 'some-utils/router'

export * from './routes'

export const go = (path: string | ((currentPath: string) => string) | Record<string, string>) => () => {
  const value = 
    typeof path === 'string' ? path : 
    typeof path === 'object' ? path[getPathname()] : 
    path(getPathname())

  setPathname(value)
}

export const goBack = () => {
  setPathname(getPathnameOld())
}
