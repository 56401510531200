import React from 'react'
import * as THREE from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader'
import { useThree } from '@react-three/fiber'
import { useComplexEffects } from 'some-utils/react'
import { AppMessage, TimeMessage } from 'messages'
import { dataURLtoBlob } from 'utils/dataUrl-blob'
import { Passes } from './Passes'
import './all-passes'

// https://github.com/mrdoob/three.js/blob/master/examples/jsm/postprocessing/EffectComposer.js
const makeScreenshot = (
  effectComposer: EffectComposer, 
  renderer: THREE.WebGLRenderer,
  camera: THREE.PerspectiveCamera,
  size: { width: number, height: number } = new THREE.Vector2(1000, 1000),
) => {
  
  const currentSize = new THREE.Vector2()
  renderer.getSize(currentSize)

  const setSize = (width: number, height: number) => {
    renderer.setSize(width, height, false)
    effectComposer.setSize(width, height)
    camera.aspect = width / height
    camera.updateProjectionMatrix()
  }

  setSize(size.width, size.height)
  AppMessage.send('BeforeScreenshot')
  effectComposer.render()
  AppMessage.send('AfterScreenshot')
  const url = renderer.domElement.toDataURL('image/jpeg', .6)

  setSize(currentSize.width, currentSize.height)

  const blob = dataURLtoBlob(url)

  return blob
}

// https://codesandbox.io/s/387z7o2zrq
export const Post = () => {
  
  const ref = React.useRef<EffectComposer>(null)
  const { scene, gl, size, camera } = useThree()
  
  React.useEffect(() => {
    ref.current!.setSize(size.width, size.height)
  }, [size])
  
  useComplexEffects(function*() {

    yield AppMessage.on('ScreenshotRequest', props => {
      props.blob = makeScreenshot(ref.current!, gl, camera as THREE.PerspectiveCamera)
    })

    yield TimeMessage.on('Render', () => {
      ref.current?.render()
    })

  }, [])

  return (
    <effectComposer ref={ref} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <Passes />
      <shaderPass attachArray="passes" args={[CopyShader]} renderToScreen />
    </effectComposer>
  )
}
