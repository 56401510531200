import { PerspectiveCamera } from 'three'

export const getCameraHeight = (camera: PerspectiveCamera, target = { x: 0, y: 0, z: 0 }) => {

  const x = target.x - camera.position.x
  const y = target.y - camera.position.y
  const z = target.z - camera.position.z
  const d = Math.sqrt(x * x + y * y + z * z)

  const angle = (camera.fov / 2) * (Math.PI / 180)
  const height = d * Math.tan(angle) * 2

  return height
}
