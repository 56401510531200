import * as THREE from 'three'
import { clamp01, dotProduct } from 'some-utils/math'
import { getTexture } from 'three-utils/getTexture'
import { makeSceneComponent } from 'three-utils/react-three-fiber'
import { AppMessage, ExperienceMessage, PostMessage, TimeMessage } from 'messages'
import { sharedUniforms } from '../SharedUniforms'
import { getUvTransform, uvTransformPoint, curve } from './uv-and-curve'
import { fragmentShader, vertexShader } from './shaders'
import { UserInput, UserInputValue } from 'store/user'
import { theme } from 'theme'

const monochromeMaskMap = getTexture(import('assets/tiles-mask-2048.png'))
const polychromeMaskMap = getTexture(import('assets/tiles-mask-2048-polychrome.png'))

const projectPointOnLine = (px: number, py: number, vx: number, vy: number, x: number, y: number) => {
  return dotProduct(x - px, y - py, vx, vy) / (vx * vx + vy * vy)
}

export const defaultParams = {
  repeat: 3,
  marginTop: .9,
  marginBottom: 1.5,
}

export const Tiles = makeSceneComponent((_, { three, addToScene, onDestroy }) => {

  onDestroy(PostMessage.pushThenPop('tiles', {
    bloomThreshold: 0.4,
    bloomStrength: 0.3,
    fxaa: true,
  }))

  // Simple copy for now.
  const params = { ...defaultParams }

  const geometry = new THREE.PlaneGeometry(20, 10)

  const uniforms = {
    ...sharedUniforms,
    uMaskMap: { value: theme.isMonochrome ? monochromeMaskMap : polychromeMaskMap },
    uUvTransform: { value: getUvTransform(params) },
    uMaskTransform: { value: new THREE.Vector4(8, 8, -4, -4) },
    uShaderPointer: { value: new THREE.Vector4(0, 0, 0, 0) },
  }
  
  const material = new THREE.ShaderMaterial({
    uniforms,
    vertexShader,
    fragmentShader,
  })
  
  addToScene(new THREE.Mesh(geometry, material))

  let neverTouched = true
  let touched = false
  let time = 0

  const copyPointer = () => {
    uniforms.uShaderPointer.value.copy(uniforms.uPointer.value)
    const bottom = new THREE.Vector2(-.02, -.44)
    const top = new THREE.Vector2(.05, .66)
    const k = projectPointOnLine(bottom.x, bottom.y, top.x - bottom.x, top.y - bottom.y, uniforms.uPointer.value.x, uniforms.uPointer.value.y)
    const value = clamp01(k)
    AppMessage.send<UserInputValue>('UserInput', { name: UserInput.Tiles, value })
  }

  onDestroy(TimeMessage.on('Update', ({ deltaTime }) => {

    time += deltaTime
    
    if (neverTouched) {
      const t = Math.cos(time * .6) * -0.5 + 0.5
      const p = uvTransformPoint(curve.getPoint(t), params)
      uniforms.uShaderPointer.value.set(p.x, p.y, 0, 0)
    }
    else if (touched) {
      copyPointer()
    }
  }))
  
  onDestroy(ExperienceMessage.on('TouchStart', () => {
    neverTouched = false
    ExperienceMessage.send('Ready')
  }))

  onDestroy(ExperienceMessage.on('TouchEnd', () => {
    copyPointer()
  }))

  onDestroy(ExperienceMessage.on('DragStart', () => {
    neverTouched = false
    touched = true
  }))

  onDestroy(ExperienceMessage.on('DragEnd', () => {
    touched = false
  }))
})
