import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass'
import { extend, ReactThreeFiber } from '@react-three/fiber'

export {
  EffectComposer,
  ShaderPass,
  RenderPass,
  UnrealBloomPass,
  AfterimagePass,
}

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  UnrealBloomPass,
  AfterimagePass,
})

declare global {
  namespace JSX {
    interface IntrinsicElements {
      effectComposer: ReactThreeFiber.Node<EffectComposer, typeof EffectComposer>
      shaderPass: ReactThreeFiber.Node<ShaderPass, typeof ShaderPass>
      renderPass: ReactThreeFiber.Node<RenderPass, typeof RenderPass>
      unrealBloomPass: ReactThreeFiber.Node<UnrealBloomPass, typeof UnrealBloomPass>
      afterimagePass: ReactThreeFiber.Node<AfterimagePass, typeof AfterimagePass>
    }
  }
}
