import { Point } from 'some-utils/geom'
import { Message } from 'some-utils/message'

type Type =
  | 'TouchFirst'
  | 'TouchStart'
  | 'TouchEnd'
  | 'DragStart'
  | 'DragEnd'
  | 'Drag'
  | 'Ready'

interface Props {
  delta: Point
} 

export class ExperienceMessage {

  static target = Symbol('ExperienceMessage')

  static send(
    type: Type,
    props?: Props,
  ) {
    Message.send(ExperienceMessage.target, type, props)
  }

  static on(
    type: Type,
    callback: (props: Props) => void,
  ) {
    return Message.on(ExperienceMessage.target, type, ({ props }) => callback(props))
  }
}
