import { colors } from 'view/glsl/colors.glsl'
import { someUtilsGlsl } from 'view/glsl/some-utils.glsl'

export const vertexShader = /* glsl */ `

  varying vec2 vUv;
  varying vec3 vPosition;
  varying vec4 vWorldPosition;

  void main()	{
    vUv = uv;
    vPosition = position;
    vWorldPosition = modelMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

export const fragmentShader = /* glsl */ `

  #define STRIPE_HEIGHT 1600.0
  #define STRIPE_PERIOD 12.0
  #define STRIPE_RATIO 0.15

  ${someUtilsGlsl}
  ${colors}
  
  uniform float uShaderTime;
  uniform vec4 uRandom;
  uniform float uOpacity;
  uniform vec3 uColor1;
  uniform vec3 uColor2;

  varying vec2 vUv;
  varying vec4 vWorldPosition;

  float dash(float y) {
    y = fract(y / STRIPE_PERIOD);
    return y < STRIPE_RATIO ? 1.0 : inverseLerp(0.5, 1.0, y) * 0.25;
  }

  // sawtooth where 1 tooth over 2 is missing
  float hollowSawtoothWave(float x, float period1, float period2, float offset) {
    float period = period1 + period2;
    float r = period1 / period;
    x = fract((x + offset) / period);
    return x < r ? x / r : 0.0;
  }

  float hollowTriangleWave(float x, float period1, float period2, float offset) {
    return toTriangleWave(hollowSawtoothWave(x, period1, period2, offset));
  }

  void main() {
    float y = vUv.y * STRIPE_HEIGHT;
    float random = uRandom.x;
    float period = mix(300.0, 1400.0, easeInout2(random));
    float offset = -mix(100.0, 600.0, random) * uShaderTime * 0.5 + -1000.0 * uRandom.x;
    float t0 = y + offset - STRIPE_HEIGHT * random;
    if (t0 > 0.0) discard;
    float t1 = hollowSawtoothWave(t0, period, period * 1.5, 0.0);
    gl_FragColor.rgb = mix(uColor1, uColor2, toTriangleWave(fract(t1 * 2.0)));
    gl_FragColor.a = uOpacity * dash(y) * easeInout3(toTriangleWave(t1)) * mix(0.3, 1.0, uRandom.y);
  }
`
