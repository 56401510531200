import React from 'react'
import { AutoPauseMessage } from 'messages'

let count = 0

export const AutoPauseDuration: React.FC<{
  value: number
}> = ({
  value,
}) => {
  
  React.useEffect(() => {
    const id = `AutoPauseDuration-${count++}`
    return AutoPauseMessage.requestDurationChange(id, value)
  })

  return null
}
