import React from 'react'
import './ChevronButton.css'

export const ChevronButton: React.FC<{
  label?: string
  noChevron?: boolean
} & React.HTMLAttributes<HTMLDivElement>> = ({ 
  label,
  noChevron = false,
  children,
  ...props
}) => {
  return (
    <div className='ChevronButton' {...props}>
      {noChevron === false && (
        <span className='Cursor LittleChevron'>&gt;</span>
      )}
      <span>{children ?? label ?? 'Bouton'}</span>
    </div>
  )
}
