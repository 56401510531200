import * as THREE from 'three'
import { PRNG } from 'some-utils/math'
import { Animation } from 'some-utils/Animation'
import { handlePointer } from 'some-utils/dom/handle-events'
import { makeSceneComponent } from 'three-utils/react-three-fiber'
import { getCameraHeight } from 'three-utils/misc'
import { AutoPauseMessage, TimeMessage } from 'messages'
import { sharedUniforms } from '../SharedUniforms'
import { fragmentShader, vertexShader } from './shaders'

const params = {
  stripeWidth: 24,
}

export const Stripes = makeSceneComponent((_, { three, addToScene, onDestroy }) => {

  const group = addToScene(new THREE.Group())

  onDestroy(AutoPauseMessage.requestDurationChange('Stripes', Infinity))

  group.position.set(0, 0, -10)

  const height = getCameraHeight(three.camera as THREE.PerspectiveCamera, group.position)
  group.scale.setScalar(height / three.size.height)

  const count = Math.ceil(three.size.width / params.stripeWidth)
  const geometry = new THREE.PlaneGeometry(params.stripeWidth, 1600)

  let timeVelocity = 0, timeVelocityDecay = 0.1
  const uShaderTime = { value: 0 }
  onDestroy(TimeMessage.on('Update', ({ deltaTime }) => {
    timeVelocity *= timeVelocityDecay ** deltaTime
    uShaderTime.value += deltaTime + timeVelocity * deltaTime
  }))

  onDestroy(handlePointer(document.body, {
    onDown: () => {
      timeVelocity = 0
    },
    onDrag: ({ delta }) => {
      timeVelocity += delta.y * -.2
    },
  }))
  
  PRNG.reset(345678)
  Array.from({ length: count }).map((_, index) => {
    
    const uniforms = {
      uShaderTime,
      uRandom: { value: new THREE.Vector4(PRNG.float(), PRNG.float() ** 2, PRNG.float() ** 3, PRNG.float() ** 4) },
      uOpacity: { value: 0 },
      uColor1: { value: new THREE.Color(PRNG.item(['#bcabce', '#f1bdbd'])) }, // center
      uColor2: { value: new THREE.Color(PRNG.item(['#ded6e8', '#93a5c2'])) }, // bounds
      ...sharedUniforms,
    }

    onDestroy(Animation.during(1, ({ progress }) => {
      uniforms.uOpacity.value = progress
    }))

    const material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms,
      transparent: true,
    })

    const stripe = new THREE.Mesh(geometry, material)
    stripe.position.x = params.stripeWidth * (index - (count - 1) / 2)
    group.add(stripe)

    onDestroy(() => material.dispose())
    
    return stripe
  })
})
