import * as THREE from 'three'

const loader = new THREE.TextureLoader()

type Options = Partial<{
  wrap: THREE.Wrapping
  wrapS: THREE.Wrapping
  wrapT: THREE.Wrapping
  filter: THREE.TextureFilter
}>

export const getTexture = (url: string | Promise<{ default: string }>, options: Options = {}) => {
  
  const texture = new THREE.Texture()
  
  const onLoad = (t: THREE.Texture) => {
    texture.copy(t)
    
    const {
      wrap = THREE.RepeatWrapping,
      wrapS = wrap,
      wrapT = wrap,
      filter = THREE.LinearFilter,
    } = options
  
    texture.wrapS = wrapS
    texture.wrapT = wrapT
    texture.generateMipmaps = false
    texture.encoding = THREE.LinearEncoding
    texture.magFilter = filter
    texture.needsUpdate = true
  }

  if (typeof url === 'string') {
    loader.load(url, onLoad)
  }
  else {
    url.then(({ default: url }) => loader.load(url, onLoad))
  }

  return texture
}
