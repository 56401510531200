import React from 'react'
import { useWebSocket } from 'utils/websocket'
import { Button, Input } from './components'
import './DebugWebsocket.css'

export const DebugWebsocket: React.FC = () => {

  const ref = React.useRef<HTMLDivElement>(null)

  useWebSocket(socket => {
    socket.addEventListener('message', function (event) {
      const div = document.createElement('div')
      const data = JSON.parse(event.data)
      div.innerHTML = JSON.stringify(data, null, '  ')
      
      const receiveDiv = ref.current!.querySelector('.Receive')!
      receiveDiv.append(div)
      if (receiveDiv.childElementCount > 10) {
        receiveDiv.firstChild?.remove()
      }
    })

    socket.addEventListener('error', event => {
      console.log(socket.readyState, event)
    })

    socket.addEventListener('open', () => {
      console.log(`Websocket open!`)
    })

    socket.addEventListener('close', () => {
      console.log(`Websocket closed.`)
    })

    ref.current!.querySelector<HTMLButtonElement>('button.Send')!.onclick = () => {
      const message = ref.current!.querySelector('input')?.value
      const sender = { type: 'client', id: 'XXX' }
      socket.send(JSON.stringify({ message, sender }))
    }

    ref.current!.querySelector<HTMLButtonElement>('button.Clear')!.onclick = () => {
      ref.current!.querySelector('.Receive')!.innerHTML = ''
    }
  })

  return (
    <div
      ref={ref}
      className='DebugWebsocket absolute-fill'
    >
      <div className='Send'>
        <Input placeholder='message'/>
        <Button className='Send'>Send</Button>
      </div>
      <div className='Receive'></div>
      <Button className='Clear'>clear</Button>
    </div>
  )
}