
import { fragmentShaders } from 'view/gl/lightcode/shaders'
import { black, sharpyColors } from './colors'

type Line = { 
  visible: boolean
  colors: [string, string]
  shader: keyof typeof fragmentShaders
}

export const lineTable = ((): Record<string, Line> => {
  const BLANK: Line = {
    visible: false,
    colors: [black, black],
    shader: 'None',
  }
  const { white, red, salad, lavanda, pink, cyan, gold } = sharpyColors
  return {
    0: BLANK,
    1: {
      visible: true,
      colors: [white, white],
      shader: 'Plain',
    },
    2: {
      visible: true,
      colors: [white, red],
      shader: 'Dash',
    },
    3: {
      visible: true,
      colors: [red, red],
      shader: 'Dots',
    },
    4: BLANK,
    5: {
      visible: true,
      colors: [salad, lavanda],
      shader: 'IrregularZigzag',
    },
    6: {
      visible: true,
      colors: [pink, pink],
      shader: 'Thin',
    },
    7: {
      visible: true,
      colors: [cyan, cyan],
      shader: 'Zigzag',
    },
    8: {
      visible: true,
      colors: [cyan, cyan],
      shader: 'Dots'
    },
    9: {
      visible: true,
      colors: [white, gold],
      shader: 'Parallel',
    },
  }
})()
