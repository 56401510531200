import { config } from 'config'
import { Message } from 'some-utils/message'
import { ITimeMessage, TimeMessage, AutoPauseMessage } from 'messages'
import { Register } from 'some-utils/collections'

export interface TimeInfo {
  appTime: number
  appDeltaTime: number
  time: number
  deltaTime: number
  frame: number
  autoPaused: boolean
  timeBeforeAutoPause: number
}

class AutoPauseHandler {

  #minDelay: number
  #maxDelay: number
  #setter: (value: number) => void
  #register = new Register<any, number>()
  
  #destroy: () => void
  get destroy() { return this.#destroy }
  
  constructor(minDelay: number, setter: (value: number) => void, {
    maxDelay = Infinity
  } = {}) {
    this.#minDelay = minDelay
    this.#maxDelay = maxDelay
    this.#setter = setter

    const listener1 = AutoPauseMessage.on('RequestDurationChange', ({ requestDurationValue, requestDurationId }) => {
      this.#register.add(requestDurationId, requestDurationValue!)
      this.reset()
    })
    
    const listener2 = AutoPauseMessage.on('CancelDurationChange', ({ requestDurationId }) => {
      this.#register.removeAll(requestDurationId)
      this.reset()
    })

    const listener3 = AutoPauseMessage.on('RequestReset', () => this.reset())

    const listener4 = AutoPauseMessage.on('LogDuration', () => console.log(...this.getMax()))

    this.#destroy = () => {
      listener1.destroy()
      listener2.destroy()
      listener3.destroy()
      listener4.destroy()
      this.#register.clear()
    }

    Object.assign(window, { getMax: () => this.getMax() })
  }

  getMax() {
    let maxKey = 'minDelay'
    let maxValue = this.#minDelay
    for (const [key, value] of this.#register.entries()) {
      if (value > maxValue) {
        maxKey = key
        maxValue = value
      }
    }
    if (maxValue > this.#maxDelay) {
      maxKey = 'maxDelay'
      maxValue = this.#maxDelay
    }
    return [maxKey, maxValue] as [string, number]
  }

  reset() {
    const [, max] = this.getMax()
    this.#setter(max)
  }
}

export const initTime = () => {

  let mounted = true
  let frameId = -1
  let autoPauseDelayMin = config.DEV ? 12 : Infinity
  let autoPauseDelayMax = Math.min(autoPauseDelayMin, config.DEV ? 40 : Infinity)
  let autoPauseDelay = autoPauseDelayMin
  let timeBeforeAutoPause = autoPauseDelay
  const maxDeltaTime = 1 / 20
  let appTime = 0, appDeltaTime = 0
  let time = 0, deltaTime = 0
  let frame = 0
  let autoPaused = false

  const autoPauseHander = new AutoPauseHandler(
    autoPauseDelayMin, 
    value => timeBeforeAutoPause = value,
    { maxDelay: autoPauseDelayMax })

  let msOld = 0

  // const reset = (value = autoPauseDelay) => timeBeforeAutoPause = Math.max(value, timeBeforeAutoPause)

  const frameLoop = (ms: number) => {
    
    if (mounted) {
      frameId = window.requestAnimationFrame(frameLoop)
    }
    
    const deltaMs = ms - msOld
    msOld = ms

    appDeltaTime = Math.min(maxDeltaTime, deltaMs / 1000)
    appTime += appDeltaTime
    timeBeforeAutoPause += -appDeltaTime

    const autoPausedNew = timeBeforeAutoPause < 0
    const autoPauseChange = autoPausedNew !== autoPaused
    autoPaused = autoPausedNew
    deltaTime = autoPaused === false ? appDeltaTime : 0

    // DEBUG
    // Object.assign(window, { deltaTime, appDeltaTime })
    const timeInfo: TimeInfo = { appTime, appDeltaTime, time, deltaTime, frame, autoPaused, timeBeforeAutoPause }

    Message.send<ITimeMessage>(TimeMessage.target, 'Update', timeInfo)

    if (autoPauseChange) {
      AutoPauseMessage.send('AutoPauseHasChanged', { timeInfo })
    }

    if (autoPaused === false) {
      Message.send<ITimeMessage>(TimeMessage.target, 'BeforeRender', timeInfo)
      Message.send(TimeMessage.target, 'Render', timeInfo)
      // three.gl.render(scene, camera) // No, because post effect
      Message.send<ITimeMessage>(TimeMessage.target, 'AfterRender', timeInfo)
      time += deltaTime
      frame++
    }

    Message.send<ITimeMessage>(TimeMessage.target, 'LateUpdate', timeInfo)
  }

  const listener = () => autoPauseHander.reset()

  // INIT
  frameId = window.requestAnimationFrame(frameLoop)
  window.addEventListener('pointerdown', listener)
  window.addEventListener('pointermove', listener)
  window.addEventListener('pointerup', listener)
  window.addEventListener('keydown', listener)
  window.addEventListener('keyup', listener)
  window.addEventListener('wheel', listener)

  return () => {
    // DESTROY
    mounted = false
    window.cancelAnimationFrame(frameId)
    window.removeEventListener('pointerdown', listener)
    window.removeEventListener('pointermove', listener)
    window.removeEventListener('pointerup', listener)
    window.removeEventListener('keydown', listener)
    window.removeEventListener('keyup', listener)
    window.removeEventListener('wheel', listener)
    autoPauseHander.destroy()
  }
}
