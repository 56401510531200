import * as monochrome from './monochrome'
import * as polychrome from './polychrome'
import * as lightcode from './lightcode'

export const theme = {
  currentTheme: 'monochrome',
  get isMonochrome() { return this.currentTheme === 'monochrome' },
  monochrome,
  polychrome,
  lightcode,
}

window.fetch('/api/theme')
  .then(response => response.json())
  .then(data => theme.currentTheme = data.theme)
