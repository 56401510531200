import * as THREE from 'three'
import { euclideanDistance, inverseLerp } from 'some-utils/math'
import { makeSceneComponent } from 'three-utils/react-three-fiber'
import { getTexture } from 'three-utils/getTexture'
import { ExperienceMessage, TimeMessage, PostMessage, AppMessage } from 'messages'
import { theme } from 'theme'
import { ringFragmentShader, ringVertexShader } from './shaders'
import { sharedUniforms } from '../SharedUniforms'
import { geometryHD } from './geometry'
import { UserInput, UserInputValue } from 'store/user'
import { curve } from './curve'
// import { createLineBackground } from './line-background'

const map = getTexture(import('assets/blob-stripe.png'))

export const LineBlob = makeSceneComponent((_, { three, addToScene, onDestroy }) => {

  onDestroy(PostMessage.pushThenPop('line-blob', {
    bloomStrength: .3,
    bloomRadius: 0.5,
    bloomThreshold: 0.4,
    // fxaa: true,
  }))

  const group = addToScene(new THREE.Group())

  const color = theme.currentTheme === 'monochrome' ? 'white' : theme.polychrome.lilas
  const uniforms =  {
    uMap: { value: map },
    uColor: { value: new THREE.Color(color) },
    uShaderPointer: { value: new THREE.Vector4(-1e3, -1e3, -1e3, -1e3) },
    uShaderTime: { value: 0 },
    ...sharedUniforms,
  }

  const material = new THREE.ShaderMaterial({
    uniforms,
    vertexShader: ringVertexShader,
    fragmentShader: ringFragmentShader,
    // wireframe: true,
    transparent: true,
    side: THREE.DoubleSide,
  })

  const mesh = new THREE.Mesh(geometryHD, material)
  mesh.scale.multiplyScalar(1.1)
  mesh.position.set(0.65, 0.85, 0)
  group.add(mesh)



  // RUNTIME:
  let time = 0
  let neverTouched = true

  const copyPointer = () => {
    const p = uniforms.uShaderPointer.value
    const m = mesh.position.clone().project(three.camera)
    p.copy(sharedUniforms.uPointer.value)
    const d = euclideanDistance(p.x - m.x, p.y - m.y)
    const value = inverseLerp(0.1, 0.5, d)
    AppMessage.send<UserInputValue>('UserInput', { name: UserInput.LineBlob, value })
  }
  
  onDestroy(TimeMessage.on('Update', ({ deltaTime }) => {
    if (neverTouched) {
      time += deltaTime
      const p = curve.getPoint((time * 0.2) % 1)
      uniforms.uShaderPointer.value.set(p.x, p.y, 0, 0)
      uniforms.uShaderTime.value += deltaTime
    }
  }))

  onDestroy(ExperienceMessage.on('DragStart', () => {
    neverTouched = false
  }))

  onDestroy(ExperienceMessage.on('TouchEnd', () => {
    copyPointer()
  }))

  onDestroy(ExperienceMessage.on('Drag', ({ delta }) => {
    uniforms.uShaderTime.value += 1 / 60 * 0.5 * delta.magnitude
    copyPointer()
  }))

  // BACKGROUND
  // const background = onDestroy(createLineBackground({ uShaderTime: uniforms.uShaderTime }))
  // mesh.add(background.mesh)
})
