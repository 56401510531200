import * as THREE from 'three'
import { getPathPoints, getSvg } from '../utils/svg'
import { defaultParams } from './Tiles'

export const curve = new THREE.SplineCurve([new THREE.Vector2(), new THREE.Vector2()])

const CENTER = { x: 200, y: 200 }
const SIZE = { x: 200, y: 300 }

getSvg(import('assets/tiles-path.svg')).then(svg => {
  const points = getPathPoints(svg, '#path', 33).map(p => {
    const x = (p.x - CENTER.x) * 2 / SIZE.x
    const y = -(p.y - CENTER.y) * 2 / SIZE.y
    return new THREE.Vector2(x, y)
  })
  curve.copy(new THREE.SplineCurve(points))
})

type Params = typeof defaultParams

export const getUvTransform = ({
  repeat, marginTop, marginBottom,
}: Params) => {
  const x = 1 / (repeat + marginTop + marginBottom)
  const y = x
  const dx = 0
  const dy = (marginBottom - marginTop + -(repeat % 2)) / 2 * y
  // const dy = (marginBottom - marginTop) / 2 * y
  return new THREE.Vector4(x, y, dx, dy)
}

/**
 * NOTE: How could it be that hard?
 * Should be very careful with maths since hours can go for intial misconception.
 */
export const uvTransformPoint = (point: THREE.Vector2, {
  repeat, marginTop, marginBottom,
}: Params) => {
  const h = repeat + marginTop + marginBottom
  const s = repeat / h

  let { x, y } = point

  y = y / 2 + 0.5

  x *= s
  y *= s
  y += marginBottom / h

  y = (y - 0.5) * 2

  return new THREE.Vector2(x, y)
}
