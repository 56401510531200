import * as THREE from 'three'
import { Animation, getMemoizedEase } from 'some-utils/Animation'
import { getPathname, getPathnameOld, location } from 'some-utils/router'
import { inout, inverseLerp, lerp, out4, PRNG, radian } from 'some-utils/math'
import { makeSceneComponent } from 'three-utils/react-three-fiber'
import { AppMessage } from 'messages'
import { routes } from 'routing'
import { pairIndexFromCenter, positionFromIndex } from './utils'
import { createLines } from './createLines'
import { handlePointer } from 'some-utils/dom/handle-events'
import { userIdGeometry, userIdMaterial, userIdUpdateTexture } from '../user-id-assets'
import { getUserId, user } from 'store/user'

export const hashSamples = {
  'bisous': '565802187535088150363154071531',
  'bisoux': '385666506081100714000914353768',
  'x3': '0123456789'.repeat(3),
  'x3random': PRNG.shuffle([...'0123456789'.repeat(3)]).join(''),
}

type Props = Partial<{
  hash: string
  debug: boolean
}>

export const Lightcode = makeSceneComponent<Props>(({
  hash = hashSamples.x3random,
  debug = false,
}, arg) => {

  const group = new THREE.Group()
  arg.group.add(group)
  group.scale.setScalar(2)
  group.rotation.set(0, 0, 0)
  


  const userId = new THREE.Mesh(userIdGeometry, userIdMaterial)
  arg.onDestroy(user.onChange(() => {
    userIdUpdateTexture(getUserId())
  }, { execute: true }))
  userId.scale.setScalar(0.55)
  userId.position.set(0, -.7, 0)
  group.add(userId)

  const lineGroup = new THREE.Group()
  const lines = createLines(hash, { debug })
  lineGroup.add(...lines.map(line => line.mesh))
  group.add(lineGroup)



  // SCREENSHOT:
  const beforeScreenshotState = {
    position: group.position.clone(),
    scale: group.scale.clone(),
    update: () => {
      beforeScreenshotState.position.copy(group.position)
      beforeScreenshotState.scale.copy(group.scale)
    },
    restore: () => {
      group.position.copy(beforeScreenshotState.position)
      group.scale.copy(beforeScreenshotState.scale)
    },
  }
  AppMessage.on('BeforeScreenshot', () => {
    beforeScreenshotState.update()
    group.position.set(0, 0, 0)
    group.scale.setScalar(3)
  })
  AppMessage.on('AfterScreenshot', () => {
    beforeScreenshotState.restore()
  })



  const animParams = {
    zero: new THREE.Vector3(0, 0, 0),
    group: {
      top: new THREE.Vector3(0, 1.2, 0),
    },
    userId: {
      position1: userId.position.clone().add(new THREE.Vector3(0, .4, 0)),
      position2: userId.position.clone(),
    },
  }

  const count = lines.length
  const animation1Data = lines.map((line, index) => {
    const evenSign = index < count / 2 ? -1 : 1
    const evenIndex = pairIndexFromCenter(index, count, true)
    const remoteAnchor = new THREE.Vector3(evenSign * 5, 0, 0)
    const centerAnchor = new THREE.Vector3(.1 * positionFromIndex(index, count) / count, 0, 0)
    return {
      ...line,
      evenSign,
      evenIndex,
      remoteAnchor,
      centerAnchor,
    }
  })
  const animation1 = (t: number) => {
    group.position.copy(animParams.zero)
    lineGroup.rotation.z = radian(90)
    userId.position.copy(animParams.userId.position1)
    userId.material.opacity = inverseLerp(0.9, 1.0, t)

    // ]-{offset}--|X{duration}XXXX|------[
    // ]-{offset}----|X{duration}XXXX|----[
    const offset = 1
    const duration = 6
    const totalDuration = offset * (count / 2) + duration
    const st = t * totalDuration
    for (const { evenIndex, mesh, remoteAnchor, centerAnchor } of animation1Data) {
      const t1 = offset * evenIndex
      const t2 = offset * evenIndex + duration
      const x = inverseLerp(t1, t2, st)
      mesh.position.lerpVectors(remoteAnchor, centerAnchor, out4(x))
    }
  }

  const animation2 = (t: number) => {
    const t1 = getMemoizedEase('cubic-bezier(.2, 0, 0, 1)')(t)
    const t2 = getMemoizedEase('cubic-bezier(.2, 0, 0, 1)')(inverseLerp(0, 0.5, t))
    group.position.lerpVectors(animParams.zero, animParams.group.top, t1)
    lineGroup.rotation.z = radian(lerp(90, 0, t1))
    userId.position.lerpVectors(animParams.userId.position1, animParams.userId.position2, t1)
    userId.material.opacity = 1
    for (const { mesh, anchor, centerAnchor } of animation1Data) {
      mesh.position.lerpVectors(centerAnchor, anchor, t2)
    }
  }

  const anim1 = Animation.during({ 
    duration: 12,
    paused: true,
    immediate: true,
    autoDestroy: false,
  }, ({ progress }) => {
    animation1(inout(progress, 1.5, 0))
  })
  anim1.onProgress(({ progress }) => AppMessage.send({ customType: 'Animation:Lightcode.Anim1:Progress' }, { progress }))
  anim1.onComplete(() => AppMessage.send({ customType: 'Animation:Lightcode.Anim1:Complete' }))
  anim1.onComplete(() => console.log('anim1 complete'))
  arg.onDestroy(anim1)
  
  const anim2 = Animation.during({
    duration: 1,
    paused: true,
    immediate: true,
    autoDestroy: false,
  }, ({ progress }) => {
    animation2(progress)
  })
  arg.onDestroy(anim2)

  // tricky
  arg.onDestroy(location.pathname.when(v => v === routes.PARTICIPATE_COMPUTE, () => {
    return handlePointer(document.body, {
      onDrag: ({ delta }) => {
        anim1.setProgress(anim1.progress + -delta.y / 1000)
      },
    })
  }))

  // Triggering animations (hard coded):
  const animUpdate = () => {
    switch(getPathname()) {
      
      case routes.PARTICIPATE_COMPUTE: {
        anim1.play({ time: 0 })
        anim2.pause()
        return
      }

      case routes.PARTICIPATE_END: {
        if (getPathnameOld() === routes.PARTICIPATE_COMPUTE) {
          anim2.play({ progress: 0 })
        }
        else {
          anim2.setProgress(1)
        }
        return
      }

      case routes.WAITING: {
        anim1.pause()
        anim2.pause()
        anim2.setProgress(1)
      }
    }
  }
  arg.onPropsChange(animUpdate)
  animUpdate()

  const lightcode = {
    lines,
    anim1,
    anim2,
  }

  Object.assign(window, { lightcode })
})
