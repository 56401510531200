import React from 'react'
import { go, goBack, routes } from 'routing'
import { DivRoute, getPathname, Router, usePathname } from 'some-utils/router'
import { safeClassName, useComplexEffects, usePromise } from 'some-utils/react'
import { handleKeyboard } from 'some-utils/dom/handle-events'
import { config } from 'config'
import { useStore } from 'store/store'
import { initUser } from 'store/user'
import { AppMessage, AutoPauseMessage } from 'messages'
import { toggleLocalhost } from 'utils/toggleLocalhost'
import { downloadImage } from 'utils/dowloadImage'
import { ChooseStream } from 'view/ChooseStream'
import { SnackBar } from 'view/Snackbar'
import { Sound } from 'view/Sound'
import { DebugWebsocket } from 'view/DebugWebsocket'
import { Experience } from 'view/experience/Experience'
import { Logo } from 'view/Logo'
import { Home } from 'view/Home'
import { GlScene } from 'view/gl/gl'
import { Waiting } from './view/Waiting'
import { ShareModal } from 'view/ShareModal'
import { Shared } from './view/Shared'
import { Debug, DebugRoutes } from './Debug'
import packageJson from '../package.json'
import './App.css'

const RouteScreen: React.PropsWithChildren<typeof DivRoute> = ({ className, ...props }) => (
  <DivRoute
    overlay
    doNotPreventScrollPositionBecauseIOSIsShit
    style={{ top: '0' }}
    className={safeClassName('Screen flex-column-center', className)}
    {...props}
  />
)

const AutoPauseDot: React.FC = () => {
  const [visible, setVisible] = React.useState(false)
  useComplexEffects(function*() {
    yield AutoPauseMessage.on('AutoPauseHasChanged', ({ timeInfo }) => setVisible(timeInfo!.autoPaused))
  }, [])
  return visible ? <div className='AutoPauseDot' /> : null
}

const App: React.FC = () => {

  const pathname = usePathname()
  const { streamName } = useStore()
  if (!streamName && pathname === '/') {
    go(routes.CHOOSE_STREAM)()
  }

  useComplexEffects(initUser, [])

  useComplexEffects(function*() {
    
    (document.firstElementChild as HTMLHtmlElement).dataset.version =  packageJson.version
    console.log(packageJson.version)

    if (config.DEV) {
      yield handleKeyboard({
        onDown: [
          ['LetterR', ({ event }) => {
            if (event.shiftKey && event.metaKey) {
              event.preventDefault()
              toggleLocalhost()
            }
          }],
          ['LetterS', ({ event: { shiftKey } }) => shiftKey && 
            downloadImage(AppMessage.send('ScreenshotRequest').props.blob)],
          ['LetterD', ({ event: { shiftKey } }) => shiftKey && 
            (getPathname() === routes.DEBUG ? goBack() : go(routes.DEBUG)())],
        ]
      })
    }
  }, [])

  const [showShare, setShowShare] = React.useState(false)
  useComplexEffects(function* () {
    yield AppMessage.on('ShareModal:Show', () => setShowShare(true))
    yield AppMessage.on('ShareModal:Hide', () => setShowShare(false))
  }, [])

  // Fix dimensions since on Safari iOS everything is broken.
  const style = React.useMemo(() => {
    const { innerWidth: width, innerHeight: height } = window
    const isMobile = width < 500
    if (isMobile) {
      return {
        width: `${width}px`,
        height: `${height}px`,
      }
    }
    return {} 
  }, [])
  
  // WAIIIIIIIIIIT FOOOOONTS PLZZZZZ!!!!!
  const fontsAreLoaded = !!usePromise(() => document.fonts.ready)

  return (
    <div style={style} className='App'>
      {fontsAreLoaded && (
        <>
          <GlScene />
    
          <Router>
            <RouteScreen path={routes.CHOOSE_STREAM}>
              <ChooseStream />
            </RouteScreen>

            <RouteScreen path={routes.DEBUG} exact>
              <Debug />
            </RouteScreen>

            <RouteScreen path={routes.DEBUG_ROUTES} exact>
              <DebugRoutes />
            </RouteScreen>

            <RouteScreen path={routes.HOME} exact>
              <Home />
            </RouteScreen>

            <RouteScreen path={routes.DEBUG_WEBSOCKET}>
              <DebugWebsocket />
            </RouteScreen>

            <RouteScreen path={routes.EXPERIENCE}>
              <Experience />
            </RouteScreen>

            <RouteScreen path={routes.WAITING}>
              <Waiting />
            </RouteScreen>

            <RouteScreen path={routes.SHARE}>
              <Shared />
            </RouteScreen>

          </Router>

          <header>
            <Logo />
            {false && <Sound />}
          </header>

          <SnackBar />

          <AutoPauseDot />

          {showShare && <ShareModal />}
        </>
      )}
    </div>
  )
}

export default App
