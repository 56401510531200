export const Spacing: React.FC<{
  size?: number
}> = ({
  size = 0
}) => {
  return (
    <div style={{
      width: `${size}px`,
      height: `${size}px`,
      flexBasis: `${size}px`,
    }} />
  )
}