import create from 'zustand'
import { enableStorage, fromStorage } from './storage'

export const useStore = create<{

  streamName: string | null
  streamAccountId: string
  setStreamName: (streamName: string) => void

  answers: [number, number, number]
  setAnswer: (index: number, value: number) => void

  userId: number
  setUserId: (id: number) => void

}>((set, get) => {
  
  // enable local save
  set = enableStorage(get, set)

  return {

    streamAccountId: 'yAkVtV',
    streamName: 'kybku8eq',
    setStreamName: streamName => set({ streamName }),
  
    answers: [-1, -1, -1],
    setAnswer: (index, value) => set(({ answers }) => {
      answers[index] = value
      return { answers }
    }),

    userId: -1,
    setUserId: userId => set({ userId }),

    // start with existing storage data (if any)
    ...fromStorage(),
  
  }
})

export const getStore = () => useStore.getState()