import React from 'react'
import { useComplexEffects } from 'some-utils/react'
import { isParentOf } from 'some-utils/dom/utils'
import { handleKeyboard, handlePointer } from 'some-utils/dom/handle-events'
import { ExperienceMessage } from 'messages'
import { ChevronButton } from '../ChevronButton'
import './Question.css'

export const ParticipateQuestion: React.FC<{
  pagination?: string
  sentence?: string
  nextLabel?: string
  previous?: () => void
  next?: () => void
}> = ({
  pagination = '?/3',
  sentence = 'Lorem or ipsum?\n That is the question.',
  nextLabel = 'Suivant',
  previous = () => { },
  next = () => { },
}) => {

    const ref = React.useRef<HTMLDivElement>(null)
    const nextRef = React.useRef<HTMLDivElement>(null)

    useComplexEffects(function* () {
      let touched = false
      yield ExperienceMessage.on('Ready', () => {
        nextRef.current?.classList.remove('hidden')
        nextRef.current?.classList.remove('fade-out')
      })

      const div = ref.current!
      const questionDiv = div.querySelector('div.Question')
      yield handlePointer(div, {
        onDownIgnore: event => isParentOf(questionDiv, event.target, { includeSelf: true }),
        onDown: () => {
          ExperienceMessage.send('TouchStart')
        },
        onUp: () => {
          ExperienceMessage.send('TouchEnd')
          ExperienceMessage.send('Ready')
        },
        onDragStart: () => {
          if (touched === false) {
            touched = true
            ExperienceMessage.send('TouchFirst')
          }
          ExperienceMessage.send('DragStart')
        },
        onDragStop: () => {
          ExperienceMessage.send('DragEnd')
          ExperienceMessage.send('Ready')
        },
        dragDistanceThreshold: 20,
        onDrag: ({ delta }) => {
          ExperienceMessage.send('Drag', { delta })
          nextRef.current?.classList.add('fade-out')
        },
      })
      yield handleKeyboard({
        onDown: [
          ['ArrowLeft', previous],
          ['ArrowRight', next],
        ],
      })
    }, [])

    return (
      <div ref={ref} className='Participate absolute-fill'>
        <div className='Question'>
          <div className='Pagination'>{pagination}</div>
          <div className='Sentence' dangerouslySetInnerHTML={{ __html: sentence.replace('\n', '<br>') }} />
          <div ref={nextRef} className='Next hidden' onClick={next}>
            <ChevronButton label={nextLabel}/>
          </div>
        </div>
      </div>
    )
  }
