import * as THREE from 'three'
import { getPathPoints, getSvg } from '../utils/svg'

export const curve = new THREE.SplineCurve([new THREE.Vector2(), new THREE.Vector2()])

const CENTER = { x: 200, y: 200 }
const SIZE = { x: 200, y: 300 }

getSvg(import('assets/blob-path.svg')).then(svg => {
  const points = getPathPoints(svg, '#path', 33).map(p => {
    const x = (p.x - CENTER.x) * 2 / SIZE.x
    const y = -(p.y - CENTER.y) * 2 / SIZE.y
    return new THREE.Vector2(x, y)
  })
  curve.copy(new THREE.SplineCurve(points))
})
