import * as glsl from 'view/glsl'

export const None = /* glsl */`

  // FRAGMENT:None

  void main() {
    discard;
  }
`

export const Plain = /* glsl */ `

  // FRAGMENT:Plain

  ${glsl.waveGlsl}
  ${glsl.easeGlsl}

  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform float uTime;
  varying vec2 vUv;

  void main() { 
    float tColor = easeInout3(toTriangleWave(fract(vUv.y * 0.4 + uTime * 0.1)));
    gl_FragColor.rgb = mix(uColor1, uColor2, tColor);
    gl_FragColor.a = 1.0;
  }
`

export const Thin = /* glsl */`

  // FRAGMENT:Thin

  ${glsl.waveGlsl}
  ${glsl.easeGlsl}

  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform float uTime;
  varying vec2 vUv;

  void main() {
    if (vUv.x < 0.25 || vUv.x > 0.75) discard;

    float tColor = easeInout3(toTriangleWave(fract(vUv.y * 0.4)));
    gl_FragColor.rgb = mix(uColor1, uColor2, tColor);
    gl_FragColor.a = 1.0;
  }
`

export const Dash = /* glsl */`

  // FRAGMENT:Dash

  ${glsl.waveGlsl}
  ${glsl.easeGlsl}

  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform float uTime;
  varying vec2 vUv;

  void main() {
    if (vUv.x < 0.25 || vUv.x > 0.75) discard;

    float x = vUv.y * 8.5 + uTime * 0.2;
    float aaf = fwidth(x);
    float alpha = smoothstep(0.0, aaf, fract(x)) * smoothstep(0.8 + aaf, 0.8, fract(x));

    float tColor = toTriangleWave(fract(vUv.y + uTime * 0.1));
    gl_FragColor.rgb = mix(uColor1, uColor2, tColor);
    gl_FragColor.a = alpha;
  }
`

export const Parallel = /* glsl */`

  // FRAGMENT:Parallel

  ${glsl.waveGlsl}
  ${glsl.easeGlsl}

  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform float uTime;
  varying vec2 vUv;

  const float count = 3.0;
  const float plain = 3.0;
  const float hollow = 1.0;
  const float th = 0.2;

  void main() {

    float x = vUv.x * (plain * count + hollow * (count - 1.0));
    float alpha = toTriangleWave(hollowSawtoothWave(x, plain, hollow)) * 4.0;

    float tColor = easeInout3(toTriangleWave(fract(vUv.y * 0.4)));
    gl_FragColor.rgb = mix(uColor1, uColor2, tColor);
    gl_FragColor.a = alpha;
  }
`

export const Zigzag = /* glsl */`

  // FRAGMENT:Zigzag

  ${glsl.waveGlsl}
  ${glsl.easeGlsl}

  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform float uTime;
  varying vec2 vUv;

  const float covering = 2.5;
  const float th = 0.9;

  void main() {
    float x = vUv.y * 33.0 + uTime * -1.1;
    float offset = toTriangleWave(fract(x));
    x = vUv.x * (covering + 1.0) - offset;
    float aaf = fwidth(x);
    float alpha = smoothstep(0.0, aaf, x) * smoothstep(covering, covering - aaf, x);

    float tColor = easeInout3(toTriangleWave(fract(vUv.y * 0.4 - uTime * 0.2)));
    gl_FragColor.rgb = mix(uColor1, uColor2, tColor);
    gl_FragColor.a = alpha;
    // gl_FragColor.rgb = mix(uColor1, vec3(1.0, 0.0, 0.0), alpha);
    // gl_FragColor.a = 1.0;
  }
`

export const IrregularZigzag = /* glsl */`

  // FRAGMENT:IrregularZigzag

  ${glsl.waveGlsl}
  ${glsl.easeGlsl}

  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform float uTime;
  varying vec2 vUv;

  const float covering = 3.0;
  const float th = 0.8;

  void main() {
    float x = vUv.y * 33.0;
    float y = x + floor(uTime * 2.0);
    y = sawtoothWave2P(y, 1.0, 4.0);
    y = sawtoothWave2P(y * 3.0, 2.0, 1.0);
    y = sawtoothWave2P(y * 5.0, 3.0, 2.0);
    y = sawtoothWave2P(y * 5.0, 3.0, 2.0);
    y = toTriangleWave(y);
    x = vUv.x * (covering + 1.0) - y;
    float aaf = fwidth(x);
    float alpha = smoothstep(0.0, aaf, x) * smoothstep(covering, covering - aaf, x);

    float tColor = easeInout3(toTriangleWave(fract(vUv.y * 0.4 - uTime * 0.2)));
    gl_FragColor.rgb = mix(uColor1, uColor2, tColor);
    gl_FragColor.a = alpha;
    // gl_FragColor.rgb = mix(uColor1, vec3(1.0, 0.0, 0.0), alpha);
    // gl_FragColor.a = 1.0;
  }
`

export const Dots = /* glsl */`

  // FRAGMENT:Dots

  ${glsl.waveGlsl}
  ${glsl.easeGlsl}

  uniform float uTime;
  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform vec2 uSize;
  varying vec2 vUv;

  void main() {

    vec2 t = vUv;
    t.y += uTime * -0.02;
    t /= uSize.yx;
    // t *= 2.0;

    float dst = 1.0 -length(fract(t) - 0.5);
    float aaf = fwidth(dst);
    float x = smoothstep(0.7, 0.7 + aaf, dst);

    float tColor = easeInout3(toTriangleWave(fract(vUv.y * 0.4)));
    gl_FragColor.rgb = mix(uColor1, uColor2, tColor);
    gl_FragColor.a = x;
  }
`

export const Zebra = /* glsl */`

  // FRAGMENT:Zebra

  ${glsl.waveGlsl}
  ${glsl.easeGlsl}

  uniform float uTime;
  uniform vec3 uColor1;
  uniform vec3 uColor2;
  uniform vec2 uSize;
  varying vec2 vUv;

  const float th = 0.9;
  const float width = 0.33;

  void main() {

    float offset = fract(uTime * 2.0) < 0.5 ? vUv.x : 1.0 - vUv.x;
    float x = (vUv.y + offset * 0.03) * 100.0;
    float dst = abs(fract(x * 0.5) - 0.5) * 2.0;
    float aaf = fwidth(dst);
    float alpha = smoothstep(0.0, -aaf, dst - width - aaf * 0.5);

    float tColor = easeInout3(toTriangleWave(fract(vUv.y * 0.4)));
    gl_FragColor.rgb = mix(uColor1, uColor2, tColor);
    gl_FragColor.a = alpha;
  }`
