import * as THREE from 'three'
import { theme } from 'theme'
import { sharedUniforms } from '../SharedUniforms'
import { positionFromIndex } from './utils'
import { fragmentShaders, vertexShader } from './shaders'

export const createLines = (hash: string, { debug = false } = {}) => {

  const count = hash.length

  const lineRatio = 2 / 3

  const full = new THREE.Vector4(1, 1, 0, 0)

  const geometry = new THREE.PlaneGeometry(1, 1, 4, 100)
  const uSize = { value: new THREE.Vector2(lineRatio / count, 1) }

  const lines = Array.from({ length: count }).map((_, index) => {

    const hashToken = hash.charAt(index)

    const uTransform = { value: full }
    const { visible, colors: [color1, color2], shader } = theme.lightcode.lineTable[hashToken]
    const uColor1 = { value: new THREE.Color(color1) }
    const uColor2 = { value: new THREE.Color(color2) }
    const uniforms = {
      uColor1,
      uColor2,
      uSize,
      uTransform,
      ...sharedUniforms,
    }
    
    const fragmentShader = fragmentShaders[shader]
    const material = new THREE.ShaderMaterial({
      uniforms,
      vertexShader,
      fragmentShader,
      transparent: true,
    })

    const anchorX = 1 / count * positionFromIndex(index, count)
    const anchor = new THREE.Vector3(anchorX, 0, 0)

    const mesh = new THREE.Mesh(geometry, material)
    mesh.visible = visible
    mesh.position.copy(anchor)

    if (debug) {
      const wire = new THREE.Mesh(new THREE.PlaneGeometry(), new THREE.MeshBasicMaterial({ wireframe: true, color: 'red' }))
      wire.scale.set(lineRatio / count, 1, 1)
      mesh.add(wire)
    }

    return { index, mesh, anchor }
  })

  return lines
}
