import { Message } from 'some-utils/message'
import { TimeInfo } from 'three-utils/react-three-fiber/time'

type Type = 
  | 'RequestReset'
  | 'RequestDurationChange'
  | 'CancelDurationChange'
  | 'AutoPauseHasChanged'
  | 'LogDuration'

type Props = {
  requestDurationValue?: number
  requestDurationId?: any
  timeInfo?: TimeInfo
}

export class AutoPauseMessage {
  
  static target = Symbol('AutoPauseMessage')

  static on(type: Type, callback: (info: Props) => void) {
    return Message.on(AutoPauseMessage.target, type, (m) => callback(m.props))
  }

  static send(type: Type, props?: Partial<Props>) {
    return Message.send(AutoPauseMessage.target, type, props)
  }

  static requestDurationChange(id: string, value: number) {
    AutoPauseMessage.send('RequestDurationChange', { requestDurationId: id, requestDurationValue: value })
    return () => {
      AutoPauseMessage.send('CancelDurationChange', { requestDurationId: id })      
    }
  }
}
