import * as THREE from 'three'
import { lerp } from 'some-utils/math'
import { getPathPoints, getSvg } from '../utils/svg'

export const geometryHD = new THREE.BufferGeometry()
export const geometryLD = new THREE.BufferGeometry()

getSvg(import('assets/blob.svg')).then(svg => {
  
  geometryHD.copy(getRingGeometry(svg, {
    revolutionSubdivision: 360,
    ringSubdivision: 80,
  }))

  geometryLD.copy(getRingGeometry(svg, {
    revolutionSubdivision: 24,
    ringSubdivision: 1,
  }))
})

export const getRingGeometry = (svg: SVGElement, {
  revolutionSubdivision = 180 * 2,
  ringSubdivision = 40 * 2,
  scale = .007,
} = {}) => {
  const [innerPoints, outerPoints] = getPathPoints(svg, ['#inner', '#outer'], revolutionSubdivision)
  const geometry = new THREE.RingGeometry(1, 2, revolutionSubdivision, ringSubdivision)

  const positionArray = geometry.getAttribute('position').array as Float32Array
  const uvArray = geometry.getAttribute('uv').array as Float32Array

  const uvRingArray = new Float32Array(uvArray)
  const uvRing = new THREE.BufferAttribute(uvRingArray, 2)
  geometry.setAttribute('uvRing', uvRing)

  for (let i = 0; i <= revolutionSubdivision; i++) {
    const u = i / revolutionSubdivision
    const { x: innerX, y: innerY } = innerPoints[i % revolutionSubdivision]
    const { x: outerX, y: outerY } = outerPoints[i % revolutionSubdivision]
    for (let j = 0; j <= ringSubdivision; j++) {
      const v = j / ringSubdivision
      const x = lerp(innerX, outerX, v)
      const y = lerp(innerY, outerY, v)
      const z = 0
      const index = j * (revolutionSubdivision + 1) + i
      positionArray[index * 3 + 0] = x * scale
      positionArray[index * 3 + 1] = y * scale
      positionArray[index * 3 + 2] = z * scale
      uvRingArray[index * 2 + 0] = u
      uvRingArray[index * 2 + 1] = v
    }
  }

  return geometry
}
