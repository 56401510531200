import React from 'react'
import { Route } from 'some-utils/router'
import { theme } from 'theme'
import { go, routes } from 'routing'
import { ParticipateIntro } from './participate/Intro'
import { ParticipateQuestion } from './participate/Question'
import { ParticipateCompute } from './participate/Compute'
import { ParticipateEnd } from './participate/End'
import './Experience.css'

export const Experience: React.FC = () => {

  return (
    <div className='Experience'>

      <Route path={routes.EXPERIENCE} exact>
        <ParticipateIntro />
      </Route>

      <Route path={routes.PARTICIPATE} exact>
        <ParticipateQuestion
          pagination='1/3'
          sentence='Calme ou agité ?'
          next={go(routes.PARTICIPATE_2)}
        />
      </Route>

      <Route path={routes.PARTICIPATE_2} exact>
        <ParticipateQuestion
          pagination='2/3'
          sentence={theme.isMonochrome ? 'Clair ou obscur ?' : 'En quelle couleur rêvez-vous ?'}
          previous={go(routes.PARTICIPATE)}
          next={go(routes.PARTICIPATE_3)}
        />
      </Route>

      <Route path={routes.PARTICIPATE_3} exact>
        <ParticipateQuestion
          pagination='3/3'
          sentence='Échec ou mat ?'
          next={go(routes.PARTICIPATE_COMPUTE)}
        />
      </Route>

      <Route path={routes.PARTICIPATE_COMPUTE}>
        <ParticipateCompute />
      </Route>

      <Route path={routes.PARTICIPATE_END}>
        <ParticipateEnd />
      </Route>

    </div>
  )
}