import { Message } from 'some-utils/message'
import { State } from 'view/gl/post/Passes'

type Type = 
  | 'Push'
  | 'Pop'

export class PostMessage {

  static target = Symbol('PostMessage')
  
  static send(type: Type, id: string, state?: Partial<State>) {
    Message.send(PostMessage.target, type, { id, state })
  }
  
  static on(type: Type, callback: (id: string, state: Partial<State>) => void) {
    return Message.on(PostMessage.target, type, ({ props }) => {
      callback(props.id, props.state)
    })
  }

  static pushThenPop(id: string, state: Partial<State>) {
    PostMessage.send('Push', id, state)
    return () => PostMessage.send('Pop', id)
  }
}
