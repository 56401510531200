import React from 'react'
import { safeClassName } from 'some-utils/react'
import './Input.css'

export const Input: React.FC<Partial<{
  underline: boolean
  submitChevron: boolean
  onSubmit: (value: string) => void
  onInput: (value: string) => void
  validator: (value: string) => boolean
  
  // input props
  value: string
  placeholder: string
  style: React.HTMLProps<HTMLInputElement>['style']

  // shorthands
  email: boolean
}>> = ({
  underline = false,
  submitChevron = false,
  validator = value => value.length > 0,
  onSubmit,
  onInput,

  // input props
  value,
  placeholder,
  style,

  // shorthands
  email,
}) => {

  const inputRef = React.useRef<HTMLInputElement>(null)
  const [submitable, setSubmitable] = React.useState(false)

  const innerOnInput = () => {
    const value = inputRef.current!.value
    onInput?.(value)
    setSubmitable(validator?.(value) ?? true)
  }

  return (
    <div
      className={safeClassName('Input', { underline, submitChevron, submitable })}
      style={style}
    >
      <input
        ref={inputRef}
        type={email ? 'email' : 'text'}
        onInput={innerOnInput}
        {...{ placeholder, value }}
      />
      <div
        className='Chevron'
        onClick={() => onSubmit?.(inputRef.current?.value ?? '')}
      >
        {'>>'}
      </div>
    </div>
  )
}
