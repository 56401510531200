
export const routes = {
  HOME:                 '/',
  DEBUG:                '/debug',
  DEBUG_WEBSOCKET:      '/debug-websocket',
  DEBUG_ROUTES:         '/debug-routes',
  CHOOSE_STREAM:        '/experience/choose-stream',
  EXPERIENCE:           '/experience',
  PARTICIPATE:          '/experience/participate',
  PARTICIPATE_2:        '/experience/participate/2',
  PARTICIPATE_3:        '/experience/participate/3',
  PARTICIPATE_COMPUTE:  '/experience/participate/compute',
  PARTICIPATE_END:      '/experience/participate/end',
  WAITING:              '/waiting',
  SHARE:                '/share',
}
