import React from 'react'
import { useComplexEffects, useRefComplexEffects } from 'some-utils/react'
import { Observable } from 'some-utils/observables'
import { Animation } from 'some-utils/Animation'
import { lerp } from 'some-utils/math'
import { isEmail } from 'some-utils/string'
import { config } from 'config'
import { apiPost } from 'api/api-post'
import { go, routes } from 'routing'
import { TimeMessage } from 'messages'
import { ReactComponent as Logo } from 'assets/ovvo-logo.svg'
import { BigChevron } from './components/BigChevron'
import { Input } from './components'
import { Spacing } from './Spacing'
import packageJson from '../../package.json'
import './Home.css'

export const Counter: React.FC<{ 
  date: Date
  displayLightcode?: boolean
} & React.HTMLAttributes<HTMLDivElement>> = ({ 
  date,
  displayLightcode = false,
  ...props
}) => {
  
  const ref = React.useRef<HTMLDivElement>(null)

  useComplexEffects(function*() {
    
    const seconds = new Observable('000')
    const damping = 0.1, start = 3
    let alpha = 1, time = 0
    yield TimeMessage.on('Update', ({ deltaTime }) => {
      const delta = Math.abs(Date.now() - date.getTime()) / 1e3
      seconds.value = Math.floor(delta).toString().padStart(7, '0')

      // Wait before start
      if ((time += deltaTime) > start) {
        alpha += ((delta % 1) - alpha) * damping
        ref.current!.style.opacity = `${lerp(.15, 1, alpha).toFixed(2)}`
      }
    })

    if (displayLightcode === false) {
      yield seconds.onChange(str => {
        ref.current!.innerHTML = `-${str}s`
      })
    }

  }, [])

  return (
    <div ref={ref} {...props} className='Counter'>
      {displayLightcode ? 'lightcode' : ''}
    </div>
  )
}

export const Home: React.FC = () => {
  // eslint-disable-next-line require-yield
  const ref = useRefComplexEffects<HTMLDivElement>(function*(div) {
    yield Animation.during(1).onComplete(() => {
      div.style.opacity = '1'
    })
  }, [])

  const [submitted, setSubmitted] = React.useState(false)
  const submit = async (value: string) => {
    const response = await apiPost({ type: 'email-subscribe', args: { email: value } })
    console.log(response)
    setSubmitted(true)
  }

  return (
    <div ref={ref} className='Home' style={{ opacity: '0' }}>
      <div className='Top'>
        <span className='normal'>Expérience Lumineuse</span>
        <span className='small'>par</span>
        <span className='normal' onClick={() => window.open(config.SHANDOR_URL)}>Shandor Chury</span>
      </div>

      {/* <Counter
        displayLightcode
        onClick={go(routes.EXPERIENCE)}
        date={new Date('2022/03/10 20:00:00 UTC+1')}/> */}

      {/* <div className='Bottom' onClick={() => window.open(config.GOOGLE_MAPS_URL)}>
        <span>34 rue de fleurus</span>
        <span>75006 paris france</span>
      </div> */}

      <BigChevron 
        // padding='48px'
        onClick={go(routes.EXPERIENCE)}
      />

      <div style={{ padding: '0 12%' }}>Prévenez-moi<br />de la prochaine activation</div>

      <Spacing size={32} />

      <div style={{ flex: '0 0 36px', alignSelf: 'stretch' }}>
        {submitted === false ? (
          <Input
            underline
            submitChevron
            email
            placeholder='moi@monmail.com'
            validator={str => isEmail(str)}
            onSubmit={submit}
          />
        ) : (
          <div>Merci</div>
        )}
      </div>

      <footer>
        <div className='Version' style={{ display: 'none' }}>{packageJson.version}</div>
        <Logo onClick={() => window.open(config.OVVO_URL)}/>
      </footer>
    </div>
  )
}
