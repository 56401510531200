import * as THREE from 'three'

/**
 * From this brilliant solution: https://threejs.org/examples/?q=wire#webgl_materials_wireframe
 */
const getCenterAttribute = (geometry: THREE.BufferGeometry) => {

  const vectors = [
    new THREE.Vector3(1, 0, 0),
    new THREE.Vector3(0, 1, 0),
    new THREE.Vector3(0, 0, 1),
  ]

  const position = geometry.getAttribute('position')
  const count = position.count
  const centers = new Float32Array(count * 3)

  for (let i = 0; i < count; i++) {
    vectors[i % 3].toArray(centers, i * 3)
  }

  return new THREE.BufferAttribute(centers, 3)
}

export const getGeometry = ({ length, heightSegments }: { length: number, heightSegments: number }) => {
  const geometry = new THREE.CylinderGeometry(6, 6, length, 6, heightSegments, true)
    .toNonIndexed() // NOTE: F*** required here! Otherwise it doesn't work
  geometry.setAttribute('center', getCenterAttribute(geometry))
  return geometry
}