import { config } from 'config'
import React from 'react'
import { user } from 'store/user'
import { api } from 'types'

const url = `${config.REMOTE_BASE}/api`

export const apiPost = async (body: api.PostMessage) => {
  const init = { 
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }
  const response = await window.fetch(url, init)
  return await response.json() as api.PostResponse
}

export const apiPostImage = async (blob: Blob) => {
  const form = new FormData()
  form.append('image', blob)
  form.append('name', `user-${user.value.id}-${user.value.hash}`)
  const response = await window.fetch(`${url}/upload`, {
    method: 'POST',
    body: form,
  })
  return await response.json() as api.PostResponse
}

export function useApiPost(body: api.PostMessage) {
  
  const [data, setData] = React.useState<api.PostResponse | null>(null)

  React.useEffect(() => {
    apiPost(body)
      .then(response => setData(response))
      .catch(e => console.error(e))
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(body)])

  return data
}
