import { SetState } from 'zustand'

const KEY = 'lightcode'

export const enableStorage = <T extends object>(get: () => T, set: SetState<T>) => {
  const setAndSave: SetState<T> = arg => {
    set(arg)
    const str = JSON.stringify(get(), (key, value) => {
      const type = typeof value
      if (type === 'function')
        return undefined
      return value
    })
    window.localStorage.setItem(KEY, str)
  }
  return setAndSave
}

export const fromStorage = () => {
  const data = JSON.parse(window.localStorage.getItem(KEY) ?? 'null')
  console.log(data)
  return data
}
