import { safeClassName } from 'some-utils/react'
import './BigChevron.css'

export const BigChevron: React.FC<Partial<{
  reverse: boolean
  padding: string
}> & React.HTMLAttributes<HTMLDivElement>> = ({
  reverse = false,
  padding = '36px',
  className,
  style,
  ...props
}) => {
  return (
    <div 
      className={safeClassName('BigChevron button', className, { reverse })}
      style={{
        transform: `translate(${reverse ? -.1 : .1}em)`,
        padding,
        ...style,
      }}
      {...props}
    >
      {reverse ? '<' : '>'}
    </div>
  )
}
