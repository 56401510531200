import { Message } from 'some-utils/message'

export type Type = 
| 'ScreenshotRequest'
| 'BeforeScreenshot'
| 'AfterScreenshot'
| 'ShareModal:Show'
| 'ShareModal:Hide'
| 'OrbitControls:Toggle'
| 'UserInput'
| { customType: string }

const safeType = (type: Type) => JSON.stringify(type) as Type

export class AppMessage {
  
  static target = Symbol('AppMessage')

  static send<T extends {} = any>(type: Type, props: T = {} as T) {
    const message = Message.send<{ target: typeof AppMessage.target, type: Type, props: T }>(AppMessage.target, safeType(type), props)
    message.type = JSON.parse(message.type as string)
    return message
  }
  
  static on<T = any>(type: Type, callback: (props: T) => void) {
    return Message.on(AppMessage.target, safeType(type), ({ props }) => {
      callback(props)
    })
  }
}

