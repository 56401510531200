import React from 'react'
import { useRefComplexEffects } from 'some-utils/react'
import { handlePointer } from 'some-utils/dom/handle-events'
import { go, routes } from 'routing'
import './Logo.css'

export const Logo: React.FC = () => {
  const ref = useRefComplexEffects<HTMLDivElement>(function* (div) {
    yield handlePointer(div, {
      onTap: 
        go(routes.HOME),
      onQuadrupleTap: 
        go(routes.DEBUG),
    })
  }, [])
  return (
    <div ref={ref} className='Logo'>
      <span className='Cursor LittleChevron'>&gt;</span>
      <span>lightcode</span>
    </div>
  )
}
