import React from 'react'
import { Button, TextField } from '@mui/material'
import { go, routes } from 'routing'
import { useStore } from 'store/store'
import { sendSnackMessage } from './Snackbar'
import './ChooseStream.css'

export const ChooseStream: React.FC = () => {
  const { streamAccountId, streamName, setStreamName } = useStore()
  const [value, setValue] = React.useState(streamName ?? '')
  const onClick = () => {
    setStreamName(value)
    sendSnackMessage(`Using stream name: ${streamName}`)
    go(routes.HOME)()
  }
  return (
    <div className='ChooseStream flex-column-stretch'>
      <h1>Stream name</h1>
      <p>account id: {streamAccountId}</p>
      <TextField
        className='TextField'
        label='stream name'
        placeholder='stream name'
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <Button
        variant='outlined'
        disabled={!streamName || streamName?.length === 0}
        onClick={onClick}
      >
        OK
      </Button>
    </div>
  )
}
