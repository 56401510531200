import React from 'react'
import { useRefComplexEffects } from 'some-utils/react'
import { handlePointer } from 'some-utils/dom/handle-events'
import { AppMessage } from 'messages'
import { go, routes } from 'routing'
import './Compute.css'

export const ParticipateCompute: React.FC = () => {
  
  const ref = useRefComplexEffects<HTMLDivElement>(function* (div) {
    let progress = 0
    yield AppMessage.on({ customType: 'Animation:Lightcode.Anim1:Progress' }, props => {
      progress = props.progress as number
    })
    yield handlePointer(div, {
      onTap: () => {
        if (progress > 0.5) {
          go(routes.PARTICIPATE_END)()
        }
      },
      onDoubleTap: () => {
        go(routes.PARTICIPATE_END)()
      },
    })
  }, [])

  return (
    <div ref={ref} className='Participate Compute absolute-fill'>
    </div>
  )
}
