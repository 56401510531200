
export const black = '#000000'

export const sharpyColors = {
  white: '#ffffff',
  red: '#c41c1a',
  salad: '#9ec233',
  lavanda: '#8f73ad',
  pink: '#ed8080',
  cyan: '#00a6eb',
  gold: '#f5c745',
}
