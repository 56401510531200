import React from 'react'
import { location } from 'some-utils/router'
import { config } from 'config'
import './Shared.css'

export const Shared: React.FC = () => {
  const backgroundImage = `url(${config.REMOTE_BASE}/images/${location.search.value})`
  return (
    <div className='Share absolute-fill flex-column-center'>
      <div className='Image' style={{ backgroundImage }}></div>
    </div>
  )
}
