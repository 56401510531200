import React from 'react'
import create from 'zustand'
import { Director, View } from '@millicast/sdk'
import { Message } from 'some-utils/message'
import { useComplexEffects } from 'some-utils/react'
import { useStore } from 'store/store'
import { sendSnackMessage } from '../view/Snackbar'

export enum PlayerStatus {
  Initializing = 'Initializing',
  Error = 'Error',
  Ready = 'Ready',
  RequestPlaying = 'RequestPlaying',
  Playing = 'Playing',
}

export interface SoundMessage extends Message.Message {
  target: 'Sound'
  type: 'RequestPlaying' | 'RequestPause' | 'RequestToggle'
}

let setSoundStatus = (playerStatus: PlayerStatus, error: Error | null = null) => {}
let getSoundStatus: () => PlayerStatus = () => PlayerStatus.Initializing

export const useSoundStore = create<{

  playerStatus: PlayerStatus
  notPlayedYet: boolean
  error: Error | null
  play: () => void
  pause: () => void
  toggle: () => void

}>((set, get) => {

  getSoundStatus = () => get().playerStatus
  setSoundStatus = (playerStatus, error = null) => {
    const notPlayedYet = get().notPlayedYet && playerStatus !== PlayerStatus.Playing
    console.log(playerStatus)
    set({ playerStatus, error, notPlayedYet })
  }

  return {
    playerStatus: PlayerStatus.Initializing,
    notPlayedYet: true,
    error: null,
    play: () => Message.send<SoundMessage>('Sound', 'RequestPlaying'),
    pause: () => Message.send<SoundMessage>('Sound', 'RequestPause'),
    toggle: () => Message.send<SoundMessage>('Sound', 'RequestToggle'),
  }
})

export const Player: React.FC<{
  debug?: boolean
}> = ({
  debug = false,
}) => {

  const { streamAccountId, streamName } = useStore()

  useComplexEffects(function* () {
    const video = document.createElement('video')
    video.id = 'millicast'
    video.playsInline = true

    if (debug === false) {
      video.style.width = '0'
    }
    else {
      // visible video (play test)
      Object.assign(video.style, {
        position: 'absolute',
        top: 0,
        left: 0,
        'max-width': '100%',
        'max-height': '30%',
        'pointer-events': 'none',
      })
    }

    document.body.append(video)
    const tokenGenerator = () => Director.getSubscriber({ streamName, streamAccountId })
    const millicastView = new View(streamName, tokenGenerator, video)
    millicastView.connect()
      .then(() => {
        setSoundStatus(PlayerStatus.Ready)
      })
      .catch((error: Error) => {
        sendSnackMessage(`Connection failed for "${streamName}"`)
        console.log(error)
        setSoundStatus(PlayerStatus.Error, error)
      })
      
    yield Message.on<SoundMessage>('Sound', 'RequestPlaying', async () => {
      switch(getSoundStatus()) {
        case PlayerStatus.Error: {
          sendSnackMessage(`Connection failed for "${streamName}"`)
          break
        }
        case PlayerStatus.Ready: {
          setSoundStatus(PlayerStatus.RequestPlaying)
          await video.play()
          setSoundStatus(PlayerStatus.Playing)
          break
        }
      }
    })

    yield Message.on<SoundMessage>('Sound', 'RequestPause', async () => {
      video.pause()
      setSoundStatus(PlayerStatus.Ready)
    })

    yield Message.on<SoundMessage>('Sound', 'RequestToggle', async () => {
      const request = getSoundStatus() !== PlayerStatus.Playing ? 'RequestPlaying' : 'RequestPause'
      Message.send<SoundMessage>('Sound', request)
    })

    yield () => {
      millicastView.stop()
      video.remove()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamAccountId, streamName, debug])

  return (
    <div className='Sound Player' />
  )
}
