import * as THREE from 'three'

const width = 1024
const height = 512

const canvas = document.createElement('canvas')
canvas.id = 'UserId-Texture'
canvas.style.display = 'none'
canvas.width = width
canvas.height = height
document.body.append(canvas)

const ctx = canvas.getContext('2d')!
ctx.fillStyle = 'red'

const texture = new THREE.CanvasTexture(canvas)

export const userIdUpdateTexture = (text: string) => {
  ctx.clearRect(0, 0, width, height)
  ctx.fillStyle = '#fff'
  ctx.font = '256px Inconsolata-normal-600'
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.fillText(text, width / 2, height / 2)

  texture.needsUpdate = true
}



export const userIdGeometry = new THREE.PlaneGeometry(2, 1)

export const userIdMaterial = new THREE.MeshBasicMaterial({
  map: texture,
  // blending: THREE.AdditiveBlending,
  transparent: true,
})
