import React from 'react'
import { safeClassName } from 'some-utils/react'
import './Button.css'

export const Button: React.FC<React.HTMLAttributes<HTMLButtonElement> & {
  label?: string
  disabled?: boolean
}> = ({
  label,
  disabled,
  children,
  className,
  ...props
}) => {
  return (
    <button
      className={safeClassName('Button', className, { disabled })} 
      {...props}
    >{label ?? children ?? '...'}</button>
  )
}
