
const DEV = /localhost|\d+\.\d+\.\d+\.\d+/i.test(window.location.href)

const REMOTE_BASE = '' 
// const REMOTE_BASE = DEV ? 'https://lightcode.art' : '' 

const SHANDOR_URL = 'https://fr.linkedin.com/in/shandor-chury'
const OVVO_URL = 'https://ovvo.studio/'
const GOOGLE_MAPS_URL = 'https://goo.gl/maps/nAiTpSZ11KLwreU59'

export const config = Object.freeze({
  DEV,
  REMOTE_BASE,
  SHANDOR_URL,
  OVVO_URL,
  GOOGLE_MAPS_URL,
})
