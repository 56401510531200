import React from 'react'
import { go, goBack, routes } from 'routing'
import { debug_createUserAndPushToCue, debug_createUserAndUseIt, user, useUserId } from 'store/user'
import { api } from 'types'
import { AppMessage } from 'messages'
import { Button, Input } from 'view/components'
import { Spacing } from 'view/Spacing'
import { apiPost } from 'api/api-post'
import './Debug.css'

const GetUser: React.FC = () => {

  const [state, setState] = React.useState({
    localUserId: user.value.id.toString(),
    localUser: null as null | api.User, 
    localUserIdIsValid: true,
  })
  const [lastUser, setLastUser] = React.useState(null as api.User | null)
  
  React.useEffect(() => {
    apiPost({ type: 'get-last-user' }).then(response => {
      const lastUser = response.data.user
      setLastUser(lastUser)
    })
  }, [])

  const trySetUserIdRef = React.useRef({ tryId: 0 })
  const trySetUserId = async (localUserId: string) => {
    const id = Number(localUserId)
    const idIsValid = Number.isFinite(id)
    setState({ ...state, localUserId, localUserIdIsValid: false })
    if (idIsValid) {
      trySetUserIdRef.current.tryId++
      const { tryId } = trySetUserIdRef.current
      const { ok, data } = await apiPost({ type: 'get-user', args: { id }})
      const tryIsStillValid = trySetUserIdRef.current.tryId === tryId
      if (tryIsStillValid) {
        setState({ ...state, localUserId, localUserIdIsValid: ok, localUser: data.user ?? null })
      }
    }
  }

  return (
    <>
      <div className='flex-row'>
        <Input 
          value={state.localUserId}
          onInput={value => trySetUserId(value)} />
        <Spacing size={4} />
        <Button
          disabled={state.localUserIdIsValid === false}
          style={{ width: '140px' }}
          label={`Set User ${state.localUserId}`}
          onClick={() => user.value = state.localUser!} />
      </div>
      <Button label={`Set last user (${lastUser?.id})`} onClick={() => user.value = lastUser!} />
    </>
  )
}

export const Debug: React.FC = () => {
  const userId = useUserId()

  return (
    <div className='Debug'>

      <div>current user: {userId}</div>

      <Button label='Routes'
        onClick={go(routes.DEBUG_ROUTES)} />

      <Button label='Toggle Orbit Controls'
        onClick={() => AppMessage.send('OrbitControls:Toggle')} />

      <GetUser />

      <Button label='Create 1 user and use it (no cue).'
        onClick={() => debug_createUserAndUseIt()} />

      <Button label='Create 1 user (cue).'
        onClick={() => debug_createUserAndPushToCue({ count: 1 })} />

      <Button label='Create 3 users (cue).'
        onClick={() => debug_createUserAndPushToCue({ count: 3 })} />

      <Button label='Create 10 users (cue).'
        onClick={() => debug_createUserAndPushToCue({ count: 10 })} />

      <Button label='EXIT'
        onClick={goBack} />

    </div>
  )
}

export const DebugRoutes: React.FC = () => {
  return (
    <div className='Debug Routes'>
      {Object.values(routes).map((route, index) => (
        <Button
          style={{ textAlign: 'left' }}
          key={index}
          label={route}
          onClick={go(route)} />
      ))}
    </div>
  )
}
