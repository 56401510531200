import React from 'react'
import { OrbitControls as ThreeOrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { useThree } from '@react-three/fiber'
import { useComplexEffects } from 'some-utils/react'
import { sendSnackMessage } from 'view/Snackbar'
import { AppMessage } from 'messages'

export const OrbitControls: React.FC<{
  element?: HTMLElement
  toggleWithSpace?: boolean
  restorePosition?: boolean
}> = ({
  element,
  toggleWithSpace = false,
  restorePosition = false,
}) => {

  const { camera, gl } = useThree()
  element = element ?? gl.domElement
  
  useComplexEffects(function* () {
    
    const controls = new ThreeOrbitControls(camera, element)
    yield () => controls.dispose()

    const setEnabled = (value: boolean) => {
      controls.enabled = value
      sendSnackMessage(value ? 'Orbit controls enabled' : 'Orbit controls disabled')
    }

    yield AppMessage.on('OrbitControls:Toggle', () => setEnabled(!controls.enabled))

    if (toggleWithSpace) {
      controls.enabled = false
      const onKeyDown = (event: KeyboardEvent): void => {
        if (event.code === 'Space') {
          event.preventDefault()
          setEnabled(!controls.enabled)
          if (restorePosition && event.shiftKey === false) {
            controls.reset()
          }
        }
      }
      window.addEventListener('keydown', onKeyDown)
      yield () => {
        window.removeEventListener('keydown', onKeyDown)
      }
    }

  }, [camera, gl, element, toggleWithSpace, restorePosition])

  return null
}
