import React from 'react'
import * as THREE from 'three'
import { RootState, useThree } from '@react-three/fiber'

type Destroyable = { destroy: () => void } | (() => void)
type PropsCallback<T = {}> = (props: T) => void
type CallbackReturn = void | THREE.Object3D | (() => void)
type Callback<T = {}> = (
  props: T,
  options: { 
    three: RootState,
    group: THREE.Group
    addToScene: <T extends THREE.Object3D>(object: T) => T
    onPropsChange: (value: PropsCallback) => void
    onDestroy: <T extends Destroyable>(destroyable: T) => T
  },
) => CallbackReturn

export const makeSceneComponent = <T extends {} = {}>(callback: Callback<T>) => (({ children, ...props }: React.PropsWithChildren<T> ) => {

  const ref = React.useRef<THREE.Group>(null)
  const three = useThree()

  const onPropsChangeRef = React.useRef<PropsCallback>(() => {})
  onPropsChangeRef.current(props)

  React.useEffect(() => {

    const group = ref.current!

    const destroyables = [] as Destroyable[]
    const onDestroy = <T extends Destroyable>(destroyable: T) => {
      destroyables.push(destroyable)
      return destroyable
    }
    const addToScene = <T extends THREE.Object3D>(object: T) => {
      three.scene.add(object)
      destroyables.push(() => object.removeFromParent())
      return object
    }
    const onPropsChange = (value: PropsCallback) => {
      onPropsChangeRef.current = value
    }
    const result = callback(props as T, { group, three, addToScene, onPropsChange, onDestroy })

    if (typeof result === 'function') {
      destroyables.push(result)
    }

    if (result && typeof result === 'object') {
      three.scene.add(result)
      destroyables.push(() => result.removeFromParent())
    }

    return () => {

      group.clear()

      for (const destroyable of destroyables) {
        if (typeof destroyable === 'function') {
          destroyable()
        }
        else {
          destroyable.destroy()
        }
      }
      destroyables.length = 0
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <group ref={ref} />
  )

}) as React.FC<T>
