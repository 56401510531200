
export function downloadImage(data: string | Blob, {
  filename = 'untitled.png',
  viaTab = false
} = {}) {

  if (typeof data !== 'string') {
    const url = URL.createObjectURL(data)
    downloadImage(url, { filename, viaTab })
    URL.revokeObjectURL(url)
    return
  }

  if (viaTab) {
    window.open(data, 'Image')
  }
  
  else {
    const a = document.createElement('a')
    a.href = data
    a.download = filename
    document.body.appendChild(a)
    a.click()
  }
}
