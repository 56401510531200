
export const vertexShader = /* glsl */ `

  #define RESET_TRANSFORM vec4(1.0, 1.0, 0.0, 0.0) 

  uniform vec2 uSize;
  uniform vec4 uTransform;
  uniform float uBlank;

  varying vec2 vUv;
  varying vec3 vPosition;
  varying vec4 vWorldPosition;

  vec2 applyTransform(vec2 point, vec4 transform) {
    return (point + transform.zw) * transform.xy;
  }

  void main()	{
    vUv = uv;
    
    // TRANSFORM
    vec4 transform = mix(uTransform, RESET_TRANSFORM, 0.0);
    vPosition.xy = applyTransform(position.xy * uSize.xy, transform);

    vWorldPosition = modelMatrix * vec4(vPosition, 1.0);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(vPosition, 1.0);
  }
`

