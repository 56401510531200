
// https://gist.github.com/wuchengwei/b7e1820d39445f431aeaa9c786753d8e

export function dataURLtoBlob(dataurl: string) {
  const arr = dataurl.split(','), 
    mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

export function blobToDataURL(blob: Blob, callback: (url: string) => void) {
  const a = new FileReader();
  a.onload = function() {callback(a.result as string);}
  a.readAsDataURL(blob);
}
