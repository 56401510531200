import React from 'react'
import { AutoPauseMessage } from 'messages'
import { initTime } from './time'

initTime()

// Quasi useless
export const FrameInvalidator: React.FC = () => {
  
  React.useEffect(() => {
    AutoPauseMessage.send('RequestReset')
  })

  return null
}
