import React from 'react'
import MuiSnackbar from '@mui/material/Snackbar'
import { useComplexEffects } from 'some-utils/react'
import { Message } from 'some-utils/message'
import { Icon } from '@iconify/react'

export interface SnackMessage extends Message.Message {
  target: 'Message'
  type: 'normal'
  message: string
}

export const sendSnackMessage = (message: string) => 
  Message.send<SnackMessage>('Message', 'normal', { message })

export const SnackBar: React.FC = () => {
  const [state, setState] = React.useState<{
    open: boolean
    message?: string
  }>({
    open: false,
  })
  const close = () => setState({ open: false })

  useComplexEffects(function*() {
    yield Message.on<SnackMessage>('Message', 'normal', m => {
      const { message } = m.props
      setState({ open: true, message })
    })
  }, [])
  
  const action = (
    <Icon
      icon='ci:close-small'
      onClick={close}
      fontSize={32}
    />
  )

  return (
    <div>
      <MuiSnackbar
        open={state.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={4000}
        onClose={close}
        message={state.message}
        action={action}
      />
    </div>
  )
}