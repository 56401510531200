import Color from 'colorjs.io'
import { clamp01 } from 'some-utils/math'

export const baseColors = ['#bcabce', '#f1bdbd', '#ded6e8', '#93a5c2']

export const getBaseColors = (whiteBlend = 0.5) => {
  return baseColors.map(color => {
    return Color.mix(color, 'white', clamp01(whiteBlend)).hex
  })
}
