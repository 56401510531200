import React from 'react'
import { go, routes } from 'routing'
import { getCurrentUserWaitingInfo, UserWaitingInfo, useUserId } from 'store/user'
import { ChevronButton } from './experience/ChevronButton'
import './Waiting.css'

const getMinSecString = (time: number) => {
  const min = Math.floor(time / 60).toFixed(0).padStart(2, '0')
  const sec = (time % 60).toFixed(0).padStart(2, '0')
  return { min, sec }
}

const useRemainingTime = ({ remainingTime = 0, timeStamp = 0 }) => {
  const [time, setTime] = React.useState(remainingTime)
  React.useEffect(() => {
    const update = () => {
      const elapsed = (Date.now() - timeStamp) / 1e3
      const time = remainingTime - elapsed
      setTime(time)
    }
    update()
    const id = window.setInterval(update, 250)
    return () => {
      window.clearInterval(id)
    }
  })
  return time
}

const BlockWaiting: React.FC<{
  info: UserWaitingInfo
}> = ({
  info,
}) => {
  const userId = useUserId()

  const { remainingTime, timeStamp } = info.waiting
  const time = useRemainingTime({ remainingTime, timeStamp })
  const { min, sec } = getMinSecString(time)

  return (
    <div className='Block BlockWaiting'>
      <div className='User'>user {userId}</div>
      <div className='Proj'>projection lightcode</div>
      <div className='Time'>00:00:{min}:{sec}</div>
      <div className='Bfor'>avant vous {info.position}</div>
    </div>
  )
}

const BlockPlaying: React.FC<{
  info: UserWaitingInfo
}> = ({
  info,
}) => {
  const userId = useUserId()

  const { remainingTime, timeStamp } = info.playing
  const time = useRemainingTime({ remainingTime, timeStamp })
  const { min, sec } = getMinSecString(time)

  return (
    <div className='Block BlockPlaying'>
      <div className='User'>user {userId}</div>
      <div className='Proj'>projection lightcode</div>
      <div className='Time'>00:00:{min}:{sec}</div>
      <div className='Curr'>en cours</div>
    </div>
  )
}

const BlockGone: React.FC = () => {
  return (
    <div className='Block BlockGone'>
      <div>Merci pour votre participation.</div>
      <ChevronButton
        label='refaire expérience'
        onClick={go(routes.EXPERIENCE)} />
    </div>
  )
}

export const Block: React.FC<{
  info: UserWaitingInfo
}> = ({
  info,
}) => {
  return (
    info.isPlaying 
    ? <BlockPlaying info={info} />
    : info.isWaiting 
    ? <BlockWaiting info={info} />
    : <BlockGone />
  )
}

export const Waiting: React.FC = () => {

  const [info, setInfo] = React.useState<UserWaitingInfo | null>(null)

  React.useEffect(() => {
    let mounted = true
    const fetchInfo = () => {
      getCurrentUserWaitingInfo().then(info => {
        if (mounted) {
          console.log(`Info updated.`)
          setInfo(info)
          window.setTimeout(fetchInfo, 3000)
        }
      })
    }
    fetchInfo()
    return () => void (mounted = false)
  }, [])

  return (
    <div className='Waiting absolute-fill'>
      <div className='Content'>
        {info && (
          <Block info={info} />
        )}
      </div>
    </div>
  )
}
