export const someUtilsGlsl = /* glsl */`

float clamp01(in float x) {
  return clamp(x, 0.0, 1.0);
}

float lerp(in float a, in float b, in float t) {
  return a + (b - a) * clamp01(t);
}

float inverseLerp(in float a, in float b, in float t) {
  return clamp01((t - a) / (b - a));
}

float inverseLerpUnclamped(float a, float b, float t) {
  return (t - a) / (b - a);
}

float to01(in float x) {
  return (x + 1.0) / 2.0;
}

float to11(in float x) {
  return x * 2.0 - 1.0;
}

float sawtoothWave(float x, float period, float offset) {
  return fract((x + offset) / period);
}

float sawtoothWave2P(float x, float period1, float period2, float offset) {
  float period = period1 + period2;
  float r = period1 / period;
  x = fract((x + offset) / period);
  return x < r ? x / r : (x - r) / (1.0 - r);
}

float toTriangleWave(float x) {
  return 2.0 * (x < 0.5 ? x : 1.0 - x);
}

float toSineWave(float y) {
  y = toTriangleWave(y);
  return 0.5 + 0.5 * cos((y - 1.0) * 3.141582);
}

float triangleWave(float x, float period, float offset) {
  return toTriangleWave(sawtoothWave(x, period, offset));
}

float triangleWave2P(float x, float period1, float period2, float offset) {
  return toTriangleWave(sawtoothWave2P(x, period1, period2, offset));
}

float easeInout2(float x) {
  return x < 0.5
    ? 2.0 * x * x
    : 1.0 - 2.0 * (x = 1.0 - x) * x;
}

float easeInout3(float x) {
  return x < 0.5
    ? 4.0 * x * x * x
    : 1.0 - 4.0 * (x = 1.0 - x) * x * x;
}

`