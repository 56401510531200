export const colors = /* glsl */`

#define aliceblue vec3(0.941, 0.973, 1.000)
#define antiquewhite vec3(0.980, 0.922, 0.843)
#define aqua vec3(0.000, 1.000, 1.000)
#define aquamarine vec3(0.498, 1.000, 0.831)
#define azure vec3(0.941, 1.000, 1.000)
#define beige vec3(0.961, 0.961, 0.863)
#define bisque vec3(1.000, 0.894, 0.769)
#define black vec3(0.000, 0.000, 0.000)
#define blanchedalmond vec3(1.000, 0.922, 0.804)
#define blue vec3(0.000, 0.000, 1.000)
#define blueviolet vec3(0.541, 0.169, 0.886)
#define brown vec3(0.647, 0.165, 0.165)
#define burlywood vec3(0.871, 0.722, 0.529)
#define cadetblue vec3(0.373, 0.620, 0.627)
#define chartreuse vec3(0.498, 1.000, 0.000)
#define chocolate vec3(0.824, 0.412, 0.118)
#define coral vec3(1.000, 0.498, 0.314)
#define cornflowerblue vec3(0.392, 0.584, 0.929)
#define cornsilk vec3(1.000, 0.973, 0.863)
#define crimson vec3(0.863, 0.078, 0.235)
#define cyan vec3(0.000, 1.000, 1.000)
#define darkblue vec3(0.000, 0.000, 0.545)
#define darkcyan vec3(0.000, 0.545, 0.545)
#define darkgoldenrod vec3(0.722, 0.525, 0.043)
#define darkgray vec3(0.663, 0.663, 0.663)
#define darkgreen vec3(0.000, 0.392, 0.000)
#define darkgrey vec3(0.663, 0.663, 0.663)
#define darkkhaki vec3(0.741, 0.718, 0.420)
#define darkmagenta vec3(0.545, 0.000, 0.545)
#define darkolivegreen vec3(0.333, 0.420, 0.184)
#define darkorange vec3(1.000, 0.549, 0.000)
#define darkorchid vec3(0.600, 0.196, 0.800)
#define darkred vec3(0.545, 0.000, 0.000)
#define darksalmon vec3(0.914, 0.588, 0.478)
#define darkseagreen vec3(0.561, 0.737, 0.561)
#define darkslateblue vec3(0.282, 0.239, 0.545)
#define darkslategray vec3(0.184, 0.310, 0.310)
#define darkslategrey vec3(0.184, 0.310, 0.310)
#define darkturquoise vec3(0.000, 0.808, 0.820)
#define darkviolet vec3(0.580, 0.000, 0.827)
#define deeppink vec3(1.000, 0.078, 0.576)
#define deepskyblue vec3(0.000, 0.749, 1.000)
#define dimgray vec3(0.412, 0.412, 0.412)
#define dimgrey vec3(0.412, 0.412, 0.412)
#define dodgerblue vec3(0.118, 0.565, 1.000)
#define firebrick vec3(0.698, 0.133, 0.133)
#define floralwhite vec3(1.000, 0.980, 0.941)
#define forestgreen vec3(0.133, 0.545, 0.133)
#define fuchsia vec3(1.000, 0.000, 1.000)
#define gainsboro vec3(0.863, 0.863, 0.863)
#define ghostwhite vec3(0.973, 0.973, 1.000)
#define goldenrod vec3(0.855, 0.647, 0.125)
#define gold vec3(1.000, 0.843, 0.000)
#define gray vec3(0.502, 0.502, 0.502)
#define green vec3(0.000, 0.502, 0.000)
#define greenyellow vec3(0.678, 1.000, 0.184)
#define grey vec3(0.502, 0.502, 0.502)
#define honeydew vec3(0.941, 1.000, 0.941)
#define hotpink vec3(1.000, 0.412, 0.706)
#define indianred vec3(0.804, 0.361, 0.361)
#define indigo vec3(0.294, 0.000, 0.510)
#define ivory vec3(1.000, 1.000, 0.941)
#define khaki vec3(0.941, 0.902, 0.549)
#define lavenderblush vec3(1.000, 0.941, 0.961)
#define lavender vec3(0.902, 0.902, 0.980)
#define lawngreen vec3(0.486, 0.988, 0.000)
#define lemonchiffon vec3(1.000, 0.980, 0.804)
#define lightblue vec3(0.678, 0.847, 0.902)
#define lightcoral vec3(0.941, 0.502, 0.502)
#define lightcyan vec3(0.878, 1.000, 1.000)
#define lightgoldenrodyellow vec3(0.980, 0.980, 0.824)
#define lightgray vec3(0.827, 0.827, 0.827)
#define lightgreen vec3(0.565, 0.933, 0.565)
#define lightgrey vec3(0.827, 0.827, 0.827)
#define lightpink vec3(1.000, 0.714, 0.757)
#define lightsalmon vec3(1.000, 0.627, 0.478)
#define lightseagreen vec3(0.125, 0.698, 0.667)
#define lightskyblue vec3(0.529, 0.808, 0.980)
#define lightslategray vec3(0.467, 0.533, 0.600)
#define lightslategrey vec3(0.467, 0.533, 0.600)
#define lightsteelblue vec3(0.690, 0.769, 0.871)
#define lightyellow vec3(1.000, 1.000, 0.878)
#define lime vec3(0.000, 1.000, 0.000)
#define limegreen vec3(0.196, 0.804, 0.196)
#define linen vec3(0.980, 0.941, 0.902)
#define magenta vec3(1.000, 0.000, 1.000)
#define maroon vec3(0.502, 0.000, 0.000)
#define mediumaquamarine vec3(0.400, 0.804, 0.667)
#define mediumblue vec3(0.000, 0.000, 0.804)
#define mediumorchid vec3(0.729, 0.333, 0.827)
#define mediumpurple vec3(0.576, 0.439, 0.859)
#define mediumseagreen vec3(0.235, 0.702, 0.443)
#define mediumslateblue vec3(0.482, 0.408, 0.933)
#define mediumspringgreen vec3(0.000, 0.980, 0.604)
#define mediumturquoise vec3(0.282, 0.820, 0.800)
#define mediumvioletred vec3(0.780, 0.082, 0.522)
#define midnightblue vec3(0.098, 0.098, 0.439)
#define mintcream vec3(0.961, 1.000, 0.980)
#define mistyrose vec3(1.000, 0.894, 0.882)
#define moccasin vec3(1.000, 0.894, 0.710)
#define navajowhite vec3(1.000, 0.871, 0.678)
#define navy vec3(0.000, 0.000, 0.502)
#define oldlace vec3(0.992, 0.961, 0.902)
#define olive vec3(0.502, 0.502, 0.000)
#define olivedrab vec3(0.420, 0.557, 0.137)
#define orange vec3(1.000, 0.647, 0.000)
#define orangered vec3(1.000, 0.271, 0.000)
#define orchid vec3(0.855, 0.439, 0.839)
#define palegoldenrod vec3(0.933, 0.910, 0.667)
#define palegreen vec3(0.596, 0.984, 0.596)
#define paleturquoise vec3(0.686, 0.933, 0.933)
#define palevioletred vec3(0.859, 0.439, 0.576)
#define papayawhip vec3(1.000, 0.937, 0.835)
#define peachpuff vec3(1.000, 0.855, 0.725)
#define peru vec3(0.804, 0.522, 0.247)
#define pink vec3(1.000, 0.753, 0.796)
#define plum vec3(0.867, 0.627, 0.867)
#define powderblue vec3(0.690, 0.878, 0.902)
#define purple vec3(0.502, 0.000, 0.502)
#define rebeccapurple vec3(0.400, 0.200, 0.600)
#define red vec3(1.000, 0.000, 0.000)
#define rosybrown vec3(0.737, 0.561, 0.561)
#define royalblue vec3(0.255, 0.412, 0.882)
#define saddlebrown vec3(0.545, 0.271, 0.075)
#define salmon vec3(0.980, 0.502, 0.447)
#define sandybrown vec3(0.957, 0.643, 0.376)
#define seagreen vec3(0.180, 0.545, 0.341)
#define seashell vec3(1.000, 0.961, 0.933)
#define sienna vec3(0.627, 0.322, 0.176)
#define silver vec3(0.753, 0.753, 0.753)
#define skyblue vec3(0.529, 0.808, 0.922)
#define slateblue vec3(0.416, 0.353, 0.804)
#define slategray vec3(0.439, 0.502, 0.565)
#define slategrey vec3(0.439, 0.502, 0.565)
#define snow vec3(1.000, 0.980, 0.980)
#define springgreen vec3(0.000, 1.000, 0.498)
#define steelblue vec3(0.275, 0.510, 0.706)
#define tan vec3(0.824, 0.706, 0.549)
#define teal vec3(0.000, 0.502, 0.502)
#define thistle vec3(0.847, 0.749, 0.847)
#define tomato vec3(1.000, 0.388, 0.278)
#define turquoise vec3(0.251, 0.878, 0.816)
#define violet vec3(0.933, 0.510, 0.933)
#define wheat vec3(0.961, 0.871, 0.702)
#define white vec3(1.000, 1.000, 1.000)
#define whitesmoke vec3(0.961, 0.961, 0.961)
#define yellow vec3(1.000, 1.000, 0.000)
#define yellowgreen vec3(0.604, 0.804, 0.196)

`