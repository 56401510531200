export const toggleLocalhost = () => {
  const { host, href } = window.location
  if (/localhost/.test(window.location.host)) {
    window.fetch(`http://${host.replace('4101', '4100')}/dev/local-ip`)
    .then(r => r.text())
    .then(ip => window.location.href = href.replace('localhost', ip))
  }
  else {
    window.location.href = href.replace(/192\.168\.\d+\.\d+/, 'localhost')
  }
}