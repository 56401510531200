import React from 'react'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
import { useThree } from '@react-three/fiber'
import { useComplexEffects } from 'some-utils/react'
import { PostMessage } from '../../../messages/PostMessage'

const defaultState = {
  fxaa: false,
  bloom: true,
  bloomStrength: 0.5,
  bloomThreshold: 0.85,
  bloomRadius: 0.8,
}

export type State = typeof defaultState

export const Passes = () => {

  const { size } = useThree()
  const [state, setState] = React.useState<State>(defaultState)

  // NOTE: simple state merge, in order of the "Push" message.
  useComplexEffects(function* () {
    const ids = [] as string[]
    const states = {} as Record<string, Partial<State>>
    const updateState = () => {
      const state = { ...defaultState }
      for (const id of ids) {
        Object.assign(state, states[id])
      }
      setState(state)
    }

    yield PostMessage.on('Push', (id, state) => {
      if (ids.includes(id) === false) {
        ids.push(id)
      }
      states[id] = { ...states[id], ...state }
      updateState()
    })

    yield PostMessage.on('Pop', id => {
      const index = ids.indexOf(id)
      if (index === -1) throw new Error('Oops.')
      ids.splice(index, 1)
      delete states[id]
      updateState()
    })
  }, [])

  return (
    <>
      {/* <afterimagePass attachArray="passes" uniforms-damp-value={.8} /> */}

      <shaderPass attachArray="passes"
        enabled={state.fxaa}
        args={[FXAAShader]}
        material-uniforms-resolution-value={[1 / size.width, 1 / size.height]} />

      <unrealBloomPass attachArray="passes"
        enabled={state.bloom}
        threshold={state.bloomThreshold}
        radius={state.bloomRadius}
        strength={state.bloomStrength} />
    </>
  )
}
