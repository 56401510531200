export const basicVertexShader = /* glsl */`

  uniform vec4 uResolution;

  varying vec2 vUv;
  varying vec3 vPosition;
  varying vec4 vWorldPosition;

  void main()	{
    vUv = uv;
    vPosition = position;
    vWorldPosition = modelMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`