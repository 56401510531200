import React from 'react'

const getHost = () => {
  // return window.location.host
  return `lightcode.art`
}

const createWebSocket = (callback: (socket: WebSocket) => void) => {
  const host = getHost()
  const protocol = /localhost/.test(host) ? 'ws' : 'wss'
  const url = `${protocol}://${host}/`.replace('4101', '4100')
  const socket = new WebSocket(url)

  callback(socket)

  const destroy = () => {
    if (socket.readyState === WebSocket.OPEN) {
      // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
      socket.close(1000, `it's ok`)
    }
  }

  return { destroy }
}

export const useWebSocket = (callback: (socket: WebSocket) => void) => {
  React.useEffect(() => {
    return createWebSocket(callback).destroy
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
