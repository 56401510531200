import glsl from 'babel-plugin-glsl/macro'

export const ringVertexShader = glsl/* glsl */`

#pragma glslify: noise = require(glsl-noise/classic/3d.glsl);

attribute vec2 uvRing;

varying vec2 vUv;
varying vec2 vUvRing;
varying vec3 vPosition;
varying vec4 vWorldPosition;
varying vec4 vCameraPosition;
varying vec3 vNormal;

uniform float uShaderTime;
uniform vec4 uResolution;

void main()	{
  vUv = uv;
  vUvRing = uvRing;

  float perturbation1 = vUvRing.y;
  perturbation1 *= perturbation1;
  perturbation1 = 1.0 - perturbation1;
  perturbation1 *= perturbation1;
  perturbation1 = 1.0 - perturbation1;

  vec3 noiseP1 = position * 1.2 + vec3(1.1) + vec3(uShaderTime, uShaderTime, uShaderTime / 4.0) * 0.2;
  vec3 noiseP2 = position * 4.2 + vec3(1.1) + vec3(-uShaderTime, uShaderTime * 0.4, uShaderTime / 4.0) * 0.2;

  vec3 p1 = vec3(
    noise(noiseP1) * -0.3, 
    noise(noiseP1) * 0.3, 
    noise(noiseP1) * 0.3);
  vec3 p2 = vec3(
    noise(noiseP2) * -0.1, 
    noise(noiseP2) * 0.1, 
    noise(noiseP2) * 0.1);
  
  float perturbation2 = perturbation1;
  perturbation2 *= perturbation2;
  perturbation2 *= perturbation2;

  vec3 p = 
    p1 * perturbation1 +
    p2 * perturbation2;

  vNormal = p;
  vPosition = position + p;
  vWorldPosition = modelMatrix * vec4(vPosition, 1.0);
  vCameraPosition = projectionMatrix * modelViewMatrix * vec4(vPosition, 1.0);
  gl_Position = vCameraPosition;
  
  // Required! to convert to "pseudo" NDC space.
  vCameraPosition *= vec4(uResolution.z, 1.0, 1.0, 1.0) / vCameraPosition.w;
}
`

export const ringFragmentShader = glsl/* glsl */`

#pragma glslify: inverseLerp = require(../../glslified/inverseLerp.glsl);
#pragma glslify: ease = require(glsl-easings/cubic-in-out);

varying vec2 vUv;
varying vec2 vUvRing;
varying vec3 vPosition;
varying vec4 vWorldPosition;
varying vec4 vCameraPosition;
varying vec3 vNormal;

uniform sampler2D uMap;
uniform vec4 uShaderPointer;
uniform vec3 uColor;
uniform float uToPixel;

void main() {

  float d = distance(uShaderPointer.xy, vCameraPosition.xy) * uToPixel;

  float lineh = mix(1.0, 0.3, vUvRing.y) * 0.1 - fract(vUvRing.x * 10.0);

  float t = inverseLerp(30.0, 80.0, d);
  t = 1.0 - t;
  t *= t;
  t = 1.0 - t;
  t *= t;

  float threshold = mix(0.85, 0.15, t);
  float linev = threshold - fract(vUvRing.y * 12.0 + threshold / 2.0 - 0.5);

  float x = smoothstep(-0.05, 0.0, linev) * smoothstep(threshold, threshold - 0.05, linev);
  vec4 texel = vec4((vNormal * 2.0 + 0.75) * x, 1.0);
  // vec4 texel = vec4((vNormal * 2.0 + 0.75) * (linev < 0.0 ? 0.0 : 1.0), 1.0);
  vec3 color = uColor * (texel.x + .1) * ((vNormal.x + vNormal.y + vNormal.z) * 4.0 + 1.1) + 0.06;
  
  gl_FragColor.rgb = color;
  gl_FragColor.a = texel.r;
}

`