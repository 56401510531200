const div = document.createElement('div')

export const getSvg = async (url: string | Promise<{ default: string} >) => {
  url = typeof url === 'string' ? url : (await url).default
  const svg = await (await window.fetch(url)).text()
  div.innerHTML = svg
  const node = div.querySelector('svg')
  if (!node)
    throw new Error(`No SVG content for "${url}"`)
  return node
}

export function getPathPoints(svg: SVGElement, pathSelector: string, count?: number): DOMPoint[]
export function getPathPoints(svg: SVGElement, pathSelector: string[], count?: number): DOMPoint[][]
export function getPathPoints(svg: SVGElement, pathSelector: string | string[], count = 16) {
  if (Array.isArray(pathSelector)) {
    return pathSelector.map(selector => getPathPoints(svg, selector, count))
  }
  const path = svg.querySelector(pathSelector) as SVGPathElement
  return pathToPoints(path, count)
}

export const pathToPoints = (path: SVGPathElement, count = 16) => {
  const len = path.getTotalLength()
  return Array.from({ length: count }).map((_, i) => {
    return path.getPointAtLength(len * i / count)
  })
}
