import glsl from 'babel-plugin-glsl/macro'

export const vertexShader = /* glsl */ `
  uniform vec4 uResolution;

  varying vec2 vUv;
  varying vec3 vPosition;
  varying vec4 vWorldPosition;
  varying vec4 vCameraPosition;

  void main()	{
    vUv = uv;
    vPosition = position;
    vWorldPosition = modelMatrix * vec4(position, 1.0);
    vCameraPosition = projectionMatrix * modelViewMatrix * vec4(position, 1.0) * vec4(uResolution.z, 1.0, 1.0, 1.0);
    vCameraPosition /= vCameraPosition.w; // Required! to convert to NDC space.
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

export const fragmentShader = glsl/* glsl */ `

  #pragma glslify: noise2d = require(glsl-noise/classic/2d.glsl);
  #pragma glslify: inverseLerp = require(../../glslified/inverseLerp.glsl);
  
  uniform float uTime;
  uniform vec4 uUvTransform;
  uniform vec4 uMaskTransform;
  uniform float uToPixel;
  uniform vec4 uShaderPointer;
  uniform sampler2D uMaskMap;
  varying vec2 vUv;
  varying vec4 vCameraPosition;

  bool checker(in vec2 coord) {
    float x = fract(coord.x / 2.0) > 0.5 ? -1.0 : 1.0;
    float y = fract(coord.y / 2.0) > 0.5 ? -1.0 : 1.0;
    return x * y < 0.0 ? true : false;
  }

  vec2 applyTransform(vec2 p, vec4 transform) {
    return (p - transform.zw) / transform.xy;
  }

  bool insideRect(vec2 p, vec4 rect) {
    return (
      p.x >= rect.x && p.x < rect.x + rect.z &&
      p.y >= rect.y && p.y < rect.y + rect.w);
  }

  vec2 rotate(vec2 point, float angle) {
    float c = cos(angle);
    float s = sin(angle);

    float x1 = point.x;
    float y1 = point.y;

    float x2 = x1 * c - y1 * s;
    float y2 = x1 * s + y1 * c;

    return vec2(x2, y2);
  }

  // vec2 rotateAround(vec2 point, vec2 origin, float angle) {
  //   return rotateAround(point, origin, angle, 1.0);
  // }

  vec2 rotateAround(vec2 point, vec2 origin, float angle, float scale) {
    float c = cos(angle);
    float s = sin(angle);

    float x1 = point.x - origin.x;
    float y1 = point.y - origin.y;

    float x2 = (x1 * c - y1 * s) / scale;
    float y2 = (x1 * s + y1 * c) / scale;

    return vec2(x2 + origin.x, y2 + origin.y);
  }

  vec3 gray(float t) {
    return vec3(mix(0.05, 0.15, t));
  }

  float manhattan(in vec3 v) {
    return abs(v.x) + abs(v.y) + abs(v.z);
  }

  vec3 innerOrganic(in vec2 pointer) {
    float d = distance(vCameraPosition.xy, pointer);
    // d = 1.0 - d;
    // d *= d;
    float n1 = noise2d(vCameraPosition.xy);
    float n2 = noise2d(vCameraPosition.xy * 10.0);
    float x = d + n1 * 0.3 + n2 * 0.05;
    x = 0.5 + 0.15 * sin(x * 100.0);
    x = clamp(x + 0.15 + 0.5 * n2, 0.0, 1.0);
    x *= x;
    x = mix(0.65, 1.0, x);
    return vec3(x);
  }

  vec3 inner(in vec4 pxMask) {
    vec2 pointer = uShaderPointer.xy;
    float d = distance(vCameraPosition.xy, pointer);
    float x = 1.0 - d;
    x = clamp(x, 0.0, 1.0);
    return pxMask.rgb * x;
  }

  float light(float d) {
    vec2 pointer = uShaderPointer.xy;
    d *= uToPixel;
    float x = clamp(1.0 - d / 300.0, 0.0, 1.0);
    x *= x;
    return 0.4 * x;
  }

  float shadow(float d) {
    d = inverseLerp(1.5, 0.50, d);
    d = mix(0.4, 1.0, d);
    return d;
  }

  void main() {
    float t = 0.5 + 0.5 * sin(uTime * 3.141592);
    vec2 coord = applyTransform(vCameraPosition.xy / 2.0, uUvTransform);
    vec2 pointer = uShaderPointer.xy;

    // ROTATION
    coord = rotateAround(coord, vec2(0), 0.07, 1.0);

    // LITTLE NOISE
    // float n = noise2d(coord * 25.0) * 0.01;
    float n = noise2d(coord * 1.0) * 0.01;
    coord += n;

    // DEFORMATION
    // vec2 v = pointer - coord;
    // coord += v * length(v) * 0.05;
    // coord += v * (1.0 - length(v)) * 0.05;

    vec2 ptCoord = applyTransform(pointer / 2.0, uUvTransform);

    vec4 pxMask = texture2D(uMaskMap, applyTransform(coord, uMaskTransform));
    vec4 ptMask = texture2D(uMaskMap, applyTransform(ptCoord, uMaskTransform));
    float d = distance(vCameraPosition.xy, pointer);
    
    vec4 rect = vec4(floor(coord), 1.0, 1.0);

    if (manhattan(ptMask.rgb) > 0.1 && insideRect(ptCoord, rect)) {
      gl_FragColor.rgb = inner(pxMask);
    }
    else {
      vec2 c = rotateAround(fract(coord), vec2(0.5), pxMask.r * 3.141592 * 2.0, 1.0);
      gl_FragColor.rgb = checker(coord) ? 
        gray(c.x * c.y) :
        gray((1.0 - c.x) * (1.0 - c.y));      
    }
    // gl_FragColor.rgb *= mix(0.5, 1.0, texture2D(uMaskMap, applyTransform(coord, uMaskTransform)).r);

    gl_FragColor.a = 1.0;
    gl_FragColor.rgb += light(d) * ptMask.rgb;
    gl_FragColor.rgb *= shadow(d);

  }
`