import React from 'react'
import { Vector2, Vector4 } from 'three'
import { useThree } from '@react-three/fiber'
import { pointerInfo } from 'some-utils/dom/pointer'
import { useComplexEffects } from 'some-utils/react'
import { TimeMessage } from 'messages'

// https://threejs.org/docs/#api/en/core/Uniform

/**
 * - `x`: width
 * - `y`: height
 * - `z`: aspect (width / height)
 * - `w`: pixel ratio (devicePixelRatio)
 */
const uResolution = { value: new Vector4(1, 1, 1, 1) }
/**
 * - `xyz`: the position
 * - `w`: the position magnitude
 */
const uCameraPosition = { value: new Vector4() }
/**
 * - `xy`: current position in pseudo NDC space
 * - `zw`: "down" position in pseudo NDC space
 */
const uPointer = { value: new Vector4(-1000, -1000, -1000, -1000) }
/**
 * Converts "pseudo NDC" space dimensions to screen pixels.
 * Equivalent to: `uResolution.y / 2.0`
 */
const uToPixel = { value: 1 }
/**
 * Time in seconds.
 */
const uTime = { value: 0 }

export const sharedUniforms = {
  uResolution,
  uToPixel,
  uCameraPosition,
  uPointer,
  uTime,
}

export const SharedUniformsHandler: React.FC = () => {

  const size = new Vector2()
  const three = useThree()

  // Object.assign(window,{ three })

  useComplexEffects(function* () {
    
    yield TimeMessage.on('BeforeRender', ({ time }) => {
      
      uTime.value = time

      uCameraPosition.value.set(
        three.camera.position.x,
        three.camera.position.y,
        three.camera.position.z,
        three.camera.position.length())
      
      const pixelRatio = three.gl.getPixelRatio()
      three.gl.getSize(size)
      uResolution.value.set(size.x, size.y, size.x / size.y, pixelRatio)
      
      const { innerWidth: width, innerHeight: height } = window
      const toNDC = (value: number, base: number) => -1 + 2 * value / base
      const marginLeft = (width - height) / 2
      uPointer.value.set(
        toNDC(pointerInfo.position.value.x - marginLeft, height),
        -toNDC(pointerInfo.position.value.y, height),
        toNDC(pointerInfo.down.value.position.x - marginLeft, height),
        -toNDC(pointerInfo.down.value.position.y, height),
      )

      uToPixel.value = height / 2

    }, { priority: Infinity })
    
  }, [])
  
  return null
}