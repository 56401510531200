import * as THREE from 'three'
import { FloatVariable } from 'some-utils/variables'
import { handleKeyboard } from 'some-utils/dom/handle-events'
import { ObservableNumber } from 'some-utils/observables'
import { makeSceneComponent } from 'three-utils/react-three-fiber'
import { getTexture } from 'three-utils/getTexture'
import { AppMessage, ExperienceMessage, PostMessage } from 'messages'
import { sharedUniforms } from '../SharedUniforms'
import { getGeometry } from './getGeometry'
import { fragmentShader, vertexShader } from './shaders'
import { UserInput } from 'store/user'
import { theme } from 'theme'

const polychromeMap = getTexture(import('assets/gradient-rainbow-polychrome.png'))
const monochromeMap = getTexture(import('assets/gradient-rainbow-bw.png'))
const shapeMap = getTexture(import('assets/tunnel-shapes.png'))

export const Tunnel = makeSceneComponent((_, { addToScene, onDestroy }) => {

  // Post
  onDestroy(PostMessage.pushThenPop('tunnel', { bloomStrength: 0.2 }))

  const length = 80
  const heightSegments = 100
  
  const geometry = getGeometry({ length, heightSegments })
  
  const uniforms = {
    ...sharedUniforms,
    uShaderTime: { value: 0 },
    uGradientMap: { value: theme.isMonochrome ? monochromeMap : polychromeMap },
    uShapeMap: { value: shapeMap },
  }
  
  const material = new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader,
    // wireframe: true,
    side: THREE.BackSide,
    transparent: true,
    uniforms,
    extensions: {
      derivatives: true,
    },
  })

  const mesh = addToScene(new THREE.Mesh(geometry, material))
  mesh.position.z = -length / 2
  mesh.rotation.x = Math.PI / 2

  const input = new ObservableNumber(0)
  input.onStepChange(0.01, value => AppMessage.send('UserInput', { name: UserInput.Tunnel, value }))

  let neverTouched = true
  let touched = false
  const initialVelocity = 0.25
  const velocityDecay = 0.1
  const touchVelocity = new FloatVariable(initialVelocity, { size: 4 })
  let shaderTime = 0

  mesh.onBeforeRender = () => {
    const deltaTime = 1 / 60
    shaderTime += touchVelocity.value * deltaTime
    uniforms.uShaderTime.value = shaderTime

    // NOTE: How awful is that? Can't retrieve the shader movement, so here are empiric "measures". LOL.
    const LOL = 8.35 - 1.19
    input.value = (shaderTime / LOL) % 1

    if (touched === false && neverTouched === false) {
      touchVelocity.newValue *= velocityDecay ** deltaTime
    }
  }

  onDestroy(ExperienceMessage.on('TouchStart', () => {
    touchVelocity.currentValue = 0
  }))

  onDestroy(ExperienceMessage.on('DragStart', () => {
    neverTouched = false
    touched = true
  }))

  onDestroy(ExperienceMessage.on('Drag', ({ delta }) => {
    touchVelocity.newValue = 0.5 * delta.y
  }))

  onDestroy(ExperienceMessage.on('DragEnd', () => {
    touched = false
  }))

  onDestroy(handleKeyboard({
    onDown: [
      [/Arrow/, () => neverTouched = false],
      ['ArrowUp', () => touchVelocity.currentValue += 4],
      ['ArrowDown', () => touchVelocity.currentValue -= 4],
    ],
  }))
})
