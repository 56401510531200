import { Message } from 'some-utils/message'
import { TimeInfo } from '../three-utils/react-three-fiber/time'

type Type = 
  | 'BeforeRender'
  | 'Render'
  | 'AfterRender'
  | 'Update'
  | 'LateUpdate'
  // | 'ResetAutoPauseRequest'
  // | 'AutoPauseChange'

export interface ITimeMessage extends Message.Message {
  target: typeof TimeMessage.target
  type: Type
  props: TimeInfo
}

export class TimeMessage {
  
  static target = Symbol('TimeMessage')

  static on(type: Type, callback: (info: TimeInfo) => void, { priority = 0 } = {}) {
    return Message.on(TimeMessage.target, type, ({ props }) => callback(props), { priority })
  }
}
