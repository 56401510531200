import React from 'react'
import { go, routes } from 'routing'
import { BigChevron } from 'view/components/BigChevron'
import LightcodePng from 'assets/Lightcode.png'
import './Intro.css'

// const texts = `
// Lightcode est la première de la série d’installations multivecteurs qui explorent l’interprétation DES IMPRESSIONS ET DE PERCEPTIONS à travers différents médias.

// Cette installation générative (i.e. qui se crée au fur et à mesure des données recueillies), après quelques simples interactions, “encode” les réponses en formes, couleurs, mouvements, séquences… créant ainsi un code lumière (lightcode) unique à chacun.

// Ces informations sont ensuite utilisées pour générer de manière dynamique un show lumière et une bande sonore ambiante qui plongent les spectateurs dans la mémoire émotionnelle collective.

// Stochastique par design, cette installation évolue et progresse à l’instar de chants d’oiseaux qui se métamorphosent mais ne se répètent jamais tout en préservant un rythme et une forme narrative.
// `.trim()

const texts = `
Lightcode est la première de la série d’installations multivecteurs qui explorent l’interprétation DES IMPRESSIONS ET DE PERCEPTIONS à travers différents médias.

Cette installation générative (i.e. qui se crée au fur et à mesure des données recueillies), après quelques simples interactions, “encode” les réponses en formes, couleurs, mouvements, séquences… créant ainsi un code lumière (lightcode) unique à chacun.

Ce lightcode sera ensuite diffusé en direct après un bref temps d'attente.`.trim()

const Texts: React.FC = () => {
  return (
    <div className='Texts'>
      {texts.split('\n\n').map((text, index) => (
        <p key={index}>{text}</p>
      ))}
    </div>
  )
}

export const ParticipateIntro: React.FC = () => {
  return (
    <div className='Participate Intro absolute-fill'>
      
      <div className='ImageWrapper'>
        <div className='Image' style={{ backgroundImage: `url(${LightcodePng})` }} />        
      </div>

      <Texts />
      
      {/* <ChevronButton 
        label='Générer mon Lightcode'
        onClick={go(routes.PARTICIPATE)}/> */}
      
      <BigChevron reverse onClick={go(routes.HOME)} />

    </div>
  )
}
