import * as THREE from 'three'
import { basicVertexShader, sdf2d, simplex3, someUtilsGlsl } from 'view/glsl'
import { cnoise3d } from 'view/glsl/classic/cnoise3d'

const backgroundFragmentShader = /* glsl */ `
  ${simplex3}
  ${cnoise3d}
  ${sdf2d}
  ${someUtilsGlsl}

  uniform float uShaderTime;
  uniform float uOpacity;
  uniform vec2 uCenter;
  uniform float uCenterOpacity;
  varying vec4 vWorldPosition;

  void main() {

    vec3 p = vec3(vWorldPosition.xy, uShaderTime * 0.1);

    // DISTANCE
    vec2 v = p.xy - uCenter;
    float d = length(v);
    d += cnoise3d(p * 0.5);
    d += simplex3(p * 1.2) * 0.1;
    
    // LINES
    float x = d;
    x += sin(x * 10.0) * 1.1;
    // x += sin(atan(v.y, v.x) * 80.0) * 0.5; // Joli ! Mais hors-sujet.
    x = fract(x);
    x *= abs(x);

    gl_FragColor.rgb = vec3(x) * 0.1 * mix(uCenterOpacity, 1.0, inverseLerp(2.8, 4.2, d));

    gl_FragColor.a = uOpacity;
  }
`

export const createLineBackground = ({
  uCenter = { value: new THREE.Vector2(.65, 1.15) },
  uShaderTime = { value: 0 },
} = {}) => {

  const uOpacity = { value: 1 }
  const uCenterOpacity = { value: 1 }
  const geometry = new THREE.PlaneGeometry(20, 10)
  const material = new THREE.ShaderMaterial({
    uniforms: {
      uShaderTime,
      uOpacity,
      uCenterOpacity,
      uCenter,
    },
    vertexShader: basicVertexShader,
    fragmentShader: backgroundFragmentShader,
    transparent: true,
  })
  const mesh = new THREE.Mesh(geometry, material)
  mesh.position.z = -1

  const destroy = () => {
    material.dispose()
    mesh.removeFromParent()
  }

  return {
    mesh,
    uOpacity,
    destroy,
  }
}
