/**
 * From:
 * - `A: 0,1,2,3,4,5` (even)
 * - `B: 0,1,2,3,4,5,6` (odd)
 * 
 * Returns:
 * - `A': 2,1,0,1,2,3` (evenDoubleCenter = false)
 * - `A": 2,1,0,0,1,2` (evenDoubleCenter = true)
 * - `B: 3,2,1,0,1,2,3`
 */
export const pairIndexFromCenter = (index: number, count: number, evenDoubleCenter = false) => {
  
  const even = count % 2

  if (evenDoubleCenter && even === 0) {
    const c = count / 2
    return index < c ? c - index - 1 : index - c
  }

  const c = (count + even) / 2 - 1
  return index < c ? c - index : index - c
}

/**
 * From:
 * - `A: 0,1,2,3,4,5` (even)
 * - `B: 0,1,2,3,4,5,6` (odd)
 * 
 * Returns:
 * - `A": 4,2,0,1,3,5`
 * - `B: 6,4,2,0,1,3,5`
 */
export const alternateIndexFromCenter = (i: number, count: number) => {
  const even = count % 2
  const c = count / 2 - even
  return i < c ? count - (i + 1) * 2 : (i + 1) * 2 - count - 1
}

export const positionFromIndex = (index: number, count: number, align = .5) => {
  return index - align * (count - 1)
}
