import React from 'react'
import copyToClipboard from 'copy-to-clipboard'
import { config } from 'config'
import { AppMessage } from 'messages'
import { handlePointer } from 'some-utils/dom/handle-events'
import { useRefComplexEffects } from 'some-utils/react'
import { getUserId, user } from 'store/user'
import { apiPostImage } from 'api/api-post'
import { sendSnackMessage } from 'view/Snackbar'
import { downloadImage } from 'utils/dowloadImage'
import './ShareModal.css'

export const ShareModal: React.FC = () => {
  
  const [imageUrl, setImageUrl] = React.useState<string | null>(null)

  React.useEffect(() => {
    const message = AppMessage.send('ScreenshotRequest', { blob: null as null | Blob })
    const { blob } = message.props
    if (blob) {
      apiPostImage(blob).then(response => {
        if (response.ok) {
          const url = `${config.REMOTE_BASE}/images/${user.value.id}`
          setImageUrl(url)
        }
        else {
          sendSnackMessage(response.data.message)
        }
      })
    }
    else {
      console.warn('Screenshot failed.')
    }
  }, [])

  const ref = useRefComplexEffects<HTMLDivElement>(function* (div) {
    yield handlePointer(div, {
      onTap: tap => (tap.upEvent.target === div) && AppMessage.send('ShareModal:Hide'),
    })
  }, [])

  const displayUrl = `lightcode.art/share?${user.value.id}`
  
  const copy = () => {
    const url = `https://lightcode.art/share?${user.value.id}`
    copyToClipboard(url)
    sendSnackMessage('Copié !')
  }

  const backgroundImage = `url(${imageUrl})`
  
  return (
    <div ref={ref} className='ShareModal'>
      <div className='Inner'>
        {imageUrl ? (
          <>
            <div
              className='Image'
              onClick={() => downloadImage(imageUrl, { filename: `lightcode-${getUserId()}` })}
              style={{ backgroundImage }}/>
            <div className='Url' onClick={copy}>{displayUrl}</div>
          </>
        ) : (
          <div>Veuillez patienter</div>
        )}
      </div>
    </div>
  )
}
